import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Ip() {
  return (
    <>
      <Helmet>
        <title>Game District IP - Game District</title>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://gamedistrictpublishing.com/GD/assets/blog_top_banner/base.css"
        />
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        <link
          rel="stylesheet"
          type="text/css"
          href="assets/blog_top_banner/base.css"
        />
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        <script src="https://tympanus.net/codrops/adpacks/cda_sponsor.js"></script>
        <script type="module" src="assets/blog_top_banner/index.js"></script>
        <script src="assets/blog_top_banner/imagesloaded.pkgd.min.js"></script>

        <script src="assets/blog_top_banner/gsap.min.js"></script>
        <script src="assets/blog_top_banner/ScrollTrigger.min.js"></script>
        <script src="assets/blog_top_banner/lenis.min.js"></script>
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
   function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
new WOW().init();

fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});

    const imageParallax = document.querySelectorAll(".parallax-image");

		if (imageParallax.length > 0) {
			imageParallax.forEach(function (element) {
				const wrapper = document.createElement("div");
				wrapper.className = "parallax-image-wrap";

				const innerWrapper = document.createElement("div");
				innerWrapper.className = "parallax-image-inner";

				element.parentNode.insertBefore(wrapper, element);
				wrapper.appendChild(innerWrapper);
				innerWrapper.appendChild(element);

				wrapper.style.overflow = "hidden";

				const animImageParallax = element;
				const imgParallaxWrapper = wrapper;
				const innerWrap = innerWrapper;

				const tlImageParallax = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						onEnter: animImgParallaxRefresh,
					},
				});

				tlImageParallax.to(animImageParallax, {
					yPercent: 35,
					ease: "none",
				});

				// Function to refresh the scroll trigger
				function animImgParallaxRefresh() {
					tlImageParallax.scrollTrigger.refresh();
				}

				// Animation setup for zoom-in effect
				const tlZoomIn = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top 100%",
					},
				});

				tlZoomIn.from(innerWrap, {
					duration: 1.5,
					opacity: 0,
					scale: 1.2,
					ease: "power2.out",
					clearProps: "all",
				});
			});
		}
      const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'

          },1000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      <div className="top_home_area">
        <Header />
        <div className="banner_ip">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner_ip_top">
                  <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                    It's Game
                    <img
                      className="star_ip"
                      alt=""
                      src="assets/images/star_ip.png"
                    />{" "}
                    Time!{" "}
                  </h2>
                  <h3 className="wow fadeInUp" data-wow-delay="0.5s">
                    Break the Mold
                    <br />
                    Build, Scale, & Monetize Your Dream IP
                  </h3>
                  <div className="btn_get_started">
                    <a href="#">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ip_banner">
            <div className="parallax-image-inner">
              <img
                className="parallax-image"
                alt=""
                src="assets/images/ip_banner.png"
              />
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>

        <div className="clr"></div>
      </div>

      <div className="pixels_code">
        <div className="banner_pixels_code">
          <div id="item-3" className="content">
            <div className="content__img-wrap">
              <div className="content__img content__img--2">
                <div
                  className="content__img-inner"
                  style={{
                    backgroundImage:
                      "url(https://gamedistrictpublishing.com/GD/assets/images/banner_pixels_code_small.png)",
                  }}
                ></div>
                <div
                  className="content__img-inner content__img-inner--hidden"
                  style={{
                    backgroundImage:
                      "url(https://gamedistrictpublishing.com/GD/assets/images/banner_pixels_code_big.png)",
                  }}
                ></div>
              </div>
            </div>
            <h2 className="content__text content__text--left">
              Do you dream in pixels and code? Are your notebooks overflowing
              with fantastical stories and captivating characters?
            </h2>
          </div>
        </div>

        <div className="craft_worlds">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3 className="animate-line-3d">
                  Craft Worlds,
                  <br /> Build Legends
                  <br /> Your IP Starts Here!
                </h3>
                <p className="animate-text-from-right">
                  Dreaming of epic worlds and legendary characters?
                  <br /> We ignite your vision and transforming captivating
                  stories into thriving gameverses. From concept to champion,
                  our expertise empowers creators of all levels to craft
                  interactive experiences that build legacies. Let's forge a
                  path for your IP to captivate players and build a passionate
                  community.
                </p>
              </div>
              <div className="col-6">
                <div className="craft_worlds_img">
                  <div className="parallax-image-inner">
                    <img
                      className="parallax-image"
                      alt=""
                      src="assets/images/craft_worlds.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="gameverse_monetize">
        <div className="container">
          <img
            className="forge_your_gameverse_icon"
            alt=""
            src="assets/images/forge_your_gameverse_icon.png"
          />

          <div className="row">
            <div className="col-6">
              <div className="gameverse_monetize_img">
                <div className="parallax-image-inner">
                  <img
                    className="parallax-image"
                    alt=""
                    src="assets/images/forge_your_gameverse.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="gameverse_monetize_content_right">
                <h3 className="animate-line-3d">
                  Forge Your
                  <br /> Gameverse!
                </h3>
                <p className="animate-text-from-right">
                  Do you have a groundbreaking IP idea brewing? We'll be your
                  guide, translating your vision into a captivating game
                  experience. From concept development to design and beyond,
                  we'll equip you with the tools and expertise to forge your own
                  gameverse.
                </p>
                <div className="btn_dream_game">
                  <a href="#">Let's Build Your Dream Game</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="gameverse_monetize">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <img
                className="level_up_icon"
                alt=""
                src="assets/images/level_up_icon.png"
              />
              <div className="gameverse_monetize_content_left">
                <h3 className="animate-line-3d">
                  Level Up
                  <br />
                  Your IP!
                </h3>
                <p className="animate-text-from-right">
                  Already boast a thriving IP? We'll help you scale it to new
                  heights. Our comprehensive services encompass innovative game
                  design, powerful marketing strategies, and expert live-ops
                  management to ensure your IP dominates the playing field.
                </p>
                <div className="btn_dream_game">
                  <a href="#">Reach New Levels of Success</a>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="level_up_game_img">
                <div className="parallax-image-inner">
                  <img
                    className="parallax-image"
                    alt=""
                    src="assets/images/level_up_game_icon.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="gameverse_monetize">
        <div className="container">
          <img
            className="forge_your_gameverse_icon"
            alt=""
            src="assets/images/monetize_icon.png"
          />
          <div className="row">
            <div className="col-6">
              <div className="monetize_game_img">
                <div className="parallax-image-inner">
                  <img
                    className="parallax-image"
                    alt=""
                    src="assets/images/monetize_game_icon.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="gameverse_monetize_content_right">
                <h3 className="animate-line-3d">
                  Monetize Your
                  <br />
                  Masterpiece!
                </h3>
                <p className="animate-text-from-right">
                  Storytellers, rejoice! Transform your characters and worlds
                  into captivating IP games. We bridge the gap between content
                  creation and game development, helping you monetize your
                  masterpiece and build a passionate gaming community.
                </p>
                <div className="btn_dream_game">
                  <a href="#">Turn Your Passion into Profit</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="roadmap">
        <div className="container">
          <img
            className="roadmap_icon"
            alt=""
            src="assets/images/roadmap_icon.png"
          />
          <div className="row">
            <div className="col-6">
              <h2>
                Roadmap
                <br />
                to Your
                <br />
                Dream IP
              </h2>
            </div>
            <div className="col-6">
              <div className="roadmap_info">
                <p className="animate-text-from-right">
                  <b>Dream Big, Build Bigger</b>
                  <br />
                  Explore our collaborative process for crafting successful IPs.
                  We guide you every step of the way, from brainstorming your
                  initial concept to launching an amazing IP.
                </p>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-3 fadein_bottom_23">
              <div className="roadmap_services">
                <div className="icon_number">01</div>
                <h3>
                  <span>It all starts with</span>
                  <br />
                  Ideation
                </h3>
                <p>
                  Brainstorming &<br /> Concept Development
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="roadmap_services">
                <div className="icon_number">02</div>
                <h3>Design & Development</h3>
                <div className="gap_hight"></div>
                <ul>
                  <li>Game Design & Prototyping</li>
                  <li>Art & Animation</li>
                </ul>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="roadmap_services">
                <div className="icon_number">03</div>
                <h3>Testing & Optimization</h3>
                <p>
                  Playtesting &<br /> Iteration
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="roadmap_services">
                <div className="icon_number">04</div>
                <h3>Launch & Beyond</h3>
                <ul>
                  <li>Strategic Marketing & User Acquisition</li>
                  <li>Live Operations & Ongoing Support</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="trusted_partner">
                <p>
                  <b>
                    Throughout this journey, Game District acts as your trusted
                    partner.
                  </b>
                  <br />
                  We provide ongoing collaboration, expert advice, and
                  transparent communication to ensure your IP reaches its full
                  potential.
                </p>
                <div className="btn_get_started">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
        <div className="container">
          <div className="ip_content">
            <div className="row">
              <div className="col-6">
                <div className="icon_ip">
                  <img alt="" src="assets/images/icon_ip.png" />
                </div>
                <p className="animate-text-from-right">
                  <span>
                    Whether you're a seasoned storyteller or an aspiring creator
                    with a groundbreaking idea, we empower you to bring your IP
                    to life.
                  </span>
                  <br />
                  <br />
                  We offer a comprehensive suite of services, from concept
                  development and meticulous game design to expert marketing
                  strategies and live-ops management.
                </p>
                <div className="btn_make_your_ip">
                  <a href="#">Make your IP</a>
                </div>
              </div>
              <div className="col-6">
                <div className="ip_content_image">
                  <div className="parallax-image-inner">
                    <img
                      className="parallax-image"
                      alt=""
                      src="assets/images/ip_content_icon.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
      <Footer />
      {/* <div className="contact_us">
        <div className="container">
          <div className="row">
          <ContactUs/>
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
}
