import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Blogs() {
  return (
    <>
      <Helmet>
        <title>Game District Blog - Game District</title>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="assets/blog_top_banner/base.css"
        />
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
        <script src="assets/js/wow.min.js"></script>
        <script src="assets/blog_top_banner/gsap.min.js"></script>
        <script src="assets/blog_top_banner/ScrollTrigger.min.js"></script>
        <script src="assets/blog_top_banner/lenis.min.js"></script>
        <script type="module" src="assets/blog_top_banner/index.js"></script>
        <script src="assets/blog_top_banner/imagesloaded.pkgd.min.js"></script>

        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
   function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
new WOW().init();

fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});
        $(".post_block_more").slice(0, 1).show(); 
			if ($(".post_block_more:hidden").length != 0) { 
				$("#load_more").show(); 
			} 
			$("#load_more").on("click", function (e) { 
				e.preventDefault(); 
				$(".post_block_more:hidden").slice(0, 1).slideDown(); 
				if ($(".post_block_more:hidden").length == 0) { 
					$("#load_more").text("No More to view") 
						.fadOut("slow"); 
				} 
			});
            const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
          },3000)
           

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      <div className="top_home_area">
        <Header />
        <div className="banner_blog" id="banner_blog">
          <div className="intro">
            <div id="item-intro" className="content content--intro">
              <div className="content__img-wrap">
                <div className="content__img content__img--1">
                  <div
                    className="content__img-inner"
                    style={{
                      backgroundImage:
                        "url(https://gamedistrictpublishing.com/GD/assets/images/banner_blog.jpg)",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="blog_banner_content2">
              <h2>
                <img
                  className="blog_banner_icon"
                  alt=""
                  src="assets/images/blog_banner_icon.png"
                />
                Just{" "}
                <img
                  className="blog_banner_icon_play"
                  alt=""
                  src="assets/images/blog_banner_icon_play.png"
                />
                make Games!{" "}
                <img
                  className="blog_banner_icon_right"
                  alt=""
                  src="assets/images/blog_banner_icon_right.png"
                />
              </h2>
              <h3>Ctrl+Alt+Create</h3>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12"></div>
            </div>
            <div className="clr"></div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="latest_blogs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <span className="latest_blogs_icon">
                  <img alt="" src="assets/images/latest_blogs_icon.png" />
                </span>
                Latest Blogs
              </h2>
              <p className="animate-text-from-right">
                Insights from the Game District Think Tank!
                <br />
                Our blog dives deep with industry insights, dev team secrets,
                <br /> and tips to propel your game to the next level.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="featured_post">
                <img alt="" src="assets/images/featured_post_img.png" />
                <div className="featured_post_info">
                  <div className="post_date">12 March 2024</div>
                  <h3>Game District Vanguard Singapore Edition</h3>
                  <p className="animate-text-from-right">
                    <b>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry
                    </b>
                    . Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s, when an unknown printer took a galley
                    of type and scrambled it to make a type specimen book...
                  </p>
                  <div className="read_more">
                    <a href="#">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="post_search">
                <div className="select">
                  <select>
                    <option>Sort by date</option>
                    <option>Sort by Name</option>
                  </select>
                </div>
                <div className="post_search_filed">
                  <input type="search" name="search" placeholder="search" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="all_post">
              <div className="post_block_more">
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-1.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-2.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-3.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-5.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post_block_more">
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-1.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-2.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post_block_more">
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-3.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-5.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post_block_more">
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-1.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-2.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="post_block_more">
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-3.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">12 March 2024</div>
                      <h3>Game District Vanguard Singapore Edition</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="blog_post fadein_bottom_23">
                    <div className="post_img">
                      <img alt="" src="assets/images/blog-post-5.png" />
                    </div>
                    <div className="post_content_info">
                      <div className="post_date">8 March 2024</div>
                      <h3>Our Wonder Women - Women’s Day</h3>
                      <p>
                        <b>Lorem Ipsum is simply dummy text</b> of the printing
                        and typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <div className="read_more">
                        <a href="#">Read more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="btn_more_post">
                <div id="load_more">Load More</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12"></div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
      <Footer />
      {/* <div className="contact_us">
        <div className="container">
          <div className="row">
          <ContactUs/>
            
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
}
