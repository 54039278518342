import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function Games() {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      //   setLoader(false);
    }, 2800);
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
        <script src="assets/js/wow.min.js"></script>

        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>

        <script src="https://unpkg.com/gsap@3/dist/ScrollTrigger.min.js"></script>
        <script src="https://unpkg.co/gsap@3/dist/gsap.min.js"></script>
        {/* <script src="assets/js/jquery.plate.js"></script> */}
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
          function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
    console.clear();
new WOW().init();
gsap.registerPlugin(ScrollTrigger);

const panels = gsap.utils.toArray(".panel");
const content = gsap.utils.toArray(".contento");

const tl = gsap.timeline({
  scrollTrigger: {
    trigger: ".sections",
    start: "top top",
    end: () => "+=" + 100 * panels.length + "%",
    pin: true,
    scrub: true,
    markers: true
  }
});

panels.forEach((panel, index) => {
  tl.from(
    panel,
    {
      xPercent: -50,
      ease: "none"
    },
    "+=0.25"
  );
	 

  tl.from(
    content[index],
    {
      xPercent: 50,
      ease: "none"
    },
    "<"
  );
tl.to(
    panel,
    {
      xPercent: 50,
      ease: "none"
    },
    "+=0.25"
  );	
tl.to(
    content[index],
    {
      xPercent: -50,
      ease: "none"
    },
    "<"
  );
	
});

 $('.accordion-list > li > .answer').hide();
 $('.accordion-list > li.active > .answer').show(); 
    
  $('.accordion-list > li').click(function() {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active").find(".answer").slideUp();
    } else {
      $(".accordion-list > li.active .answer").slideUp();
      $(".accordion-list > li.active").removeClass("active");
      $(this).addClass("active").find(".answer").slideDown();
    }
    return false;
  });

  var namespace = 'jquery-plate';

  function Plate($element, options) {
        this.config(options);

        this.$container = $element;
        if (this.options.element) {
            if (typeof this.options.element === 'string') {
                this.$element = this.$container.find(this.options.element);
            } else {
                this.$element = $(this.options.element);
            }
        } else {
            this.$element = $element;
        }

        this.originalTransform = this.$element.css('transform');
        this.$container
            .on('mouseenter.' + namespace, this.onMouseEnter.bind(this))
            .on('mouseleave.' + namespace, this.onMouseLeave.bind(this))
            .on('mousemove.' + namespace, this.onMouseMove.bind(this));
    }

    Plate.prototype.config = function(options) {
        this.options = $.extend({
            inverse: false,
            perspective: 500,
            maxRotation: 5,
            animationDuration: 200
        }, this.options, options);
    };

    Plate.prototype.destroy = function() {
        this.$element.css('transform', this.originalTransform);
        this.$container.off('.' + namespace);
    };

    Plate.prototype.update = function(offsetX, offsetY, duration) {
        var rotateX;
        var rotateY;

        if (offsetX || offsetX === 0) {
            var height = this.$container.outerHeight();
            var py = (offsetY - height / 2) / (height / 2);
            rotateX = this.round(this.options.maxRotation * -py);
        } else {
            rotateY = 0;
        }

        if (offsetY || offsetY === 0) {
            var width = this.$container.outerWidth();
            var px = (offsetX - width / 2) / (width / 2);
            rotateY = this.round(this.options.maxRotation * px);
        } else {
            rotateX = 0;
        }

        if (this.options.inverse) {
            rotateX *= -1;
            rotateY *= -1;
        }

        if (duration) {
            this.animate(rotateX, rotateY, duration);
        } else if (this.animation && this.animation.remaining) {
            this.animation.targetX = rotateX;
            this.animation.targetY = rotateY;
        } else {
            this.transform(rotateX, rotateY);
        }
    };

    Plate.prototype.reset = function(duration) {
        this.update(null, null, duration);
    };

    Plate.prototype.transform = function(rotateX, rotateY) {
        this.currentX = rotateX;
        this.currentY = rotateY;
        this.$element.css('transform',
            (this.originalTransform && this.originalTransform !== 'none' ? this.originalTransform + ' ' : '') +
            'perspective(' + this.options.perspective + 'px) ' +
            'rotateX(' + rotateX + 'deg) rotateY(' + rotateY + 'deg)'
        );
    };

    Plate.prototype.animate = function(rotateX, rotateY, duration) {
        if (duration) {
            this.animation = this.animation || {};

            var remaining = this.animation.remaining;
            this.animation.time = performance.now();
            this.animation.remaining = duration || null;
            this.animation.targetX = rotateX;
            this.animation.targetY = rotateY;

            if (!remaining) {
                requestAnimationFrame(this.onAnimationFrame.bind(this));
            }
        } else {
            this.transform(rotateX, rotateY);
        }
    };

    Plate.prototype.round = function(number) {
        return Math.round(number * 1000) / 1000;
    };

    Plate.prototype.offsetCoords = function(event) {
        var offset = this.$container.offset();
        return {
            x: event.pageX - offset.left,
            y: event.pageY - offset.top
        };
    };

    Plate.prototype.onAnimationFrame = function(timestamp) {
        this.animation = this.animation || {};

        var delta = timestamp - (this.animation.time || 0);
        this.animation.time = timestamp;

        var duration = this.animation.remaining || 0;
        var percent = Math.min(delta / duration, 1);
        var currentX = this.currentX || 0;
        var currentY = this.currentY || 0;
        var targetX = this.animation.targetX || 0;
        var targetY = this.animation.targetY || 0;
        var rotateX = this.round(currentX + (targetX - currentX) * percent);
        var rotateY = this.round(currentY + (targetY - currentY) * percent);
        this.transform(rotateX, rotateY);

        var remaining = duration - delta;
        this.animation.remaining = remaining > 0 ? remaining : null;
        if (remaining > 0) {
            requestAnimationFrame(this.onAnimationFrame.bind(this));
        }
    };

    Plate.prototype.onMouseEnter = function(event) {
        var offset = this.offsetCoords(event);
        this.update(offset.x, offset.y, this.options.animationDuration);
    };

    Plate.prototype.onMouseLeave = function(event) {
        this.reset(this.options.animationDuration);
    };

    Plate.prototype.onMouseMove = function(event) {
        var offset = this.offsetCoords(event);
        this.update(offset.x, offset.y);
    };

    $.fn.plate = function(options) {
        return this.each(function() {
            var $element = $(this);
            var plate = $element.data(namespace);

            if (options === 'remove') {
                plate.destroy();
                $element.data(namespace, null);
            } else {
                if (!plate) {
                    plate = new Plate($element, options);
                    $element.data(namespace, plate);
                    plate.reset();
                } else {
                    plate.config(options);
                }
            }
        });
    };




$('#listener-remote').plate({
                element: $('#plate-remote')
            });
            $('#listener-remote1').plate({
                element: $('#plate-remote1')
            });
            $('#listener-remote2').plate({
                element: $('#plate-remote2')
            });
            $('#listener-remote3').plate({
                element: $('#plate-remote3')
            });

            fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});
    const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
          },3000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
        <title>Game District Games - Game District</title>
      </Helmet>
      {loader ? (
        <div className="loaderLayout">
          <img alt="" src="assets/images/logo.png" className="loaderLogo" />
        </div>
      ) : null}
      <div className="top_home_area">
        <Header />
        <div className="banner_games">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                  You love
                  <img
                    className="games_icon_love"
                    alt=""
                    src="assets/images/games_icon_love.png"
                  />
                </h2>
                <h3 className="wow fadeInUp" data-wow-delay="0.5s">
                  <img
                    className="games_icon_title_left"
                    alt=""
                    src="assets/images/games_icon_title_left.png"
                  />
                  Awesome Games
                  <img
                    className="games_icon_title_right"
                    alt=""
                    src="assets/images/games_icon_title_right.png"
                  />
                </h3>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
        <div className="logos_games">
          <div className="row">
            <div className="col-12">
              <div className="slider">
                <div className="slide-track">
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_1.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_2.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_3.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_4.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_5.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_6.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_7.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_8.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_1.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_2.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_3.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_4.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_5.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_6.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_7.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_8.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_1.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_2.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_3.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_4.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_5.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_6.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_7.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_8.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_1.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_2.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_3.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_4.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_5.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_6.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_7.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_8.png" />
                  </div>
                </div>
              </div>

              <div className="slider">
                <div className="slide-track2">
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_9.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_10.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_11.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_12.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_13.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_14.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_15.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_16.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_9.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_10.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_11.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_12.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_13.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_14.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_15.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_16.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_9.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_10.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_11.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_12.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_13.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_14.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_15.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_16.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_9.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_10.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_11.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_12.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_13.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_14.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_15.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_16.png" />
                  </div>
                </div>
              </div>
              <div className="slider">
                <div className="slide-track3">
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_17.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_18.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_19.png" />
                  </div>
                  <div className="slide">
                    <img alt="" src="assets/images/games_icon_20.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="our_favorite_games">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                className="icon_our_favorite_games"
                alt=""
                src="assets/images/icon_our_favorite_games.png"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h1 className="animate-line-3d">
                Our{" "}
                <a href="#">
                  <img alt="" src="assets/images/google-play.png" />
                </a>
                <a href="#">
                  <img alt="" src="assets/images/app-store.png" />
                </a>
                <br />
                Favorite
                <br />
                Games
                <img
                  className="favoritegame-title-icon"
                  alt=""
                  src="assets/images/game-title-icon.png"
                />
              </h1>
              <ul className="games_type">
                <li>
                  <a href="#">Action</a>
                </li>
                <li>
                  <a href="#">Strategy</a>
                </li>
                <li>
                  <a href="#">Kids</a>
                </li>
                <li>
                  <a href="#">Education</a>
                </li>
                <li>
                  <a href="#">Adventure</a>
                </li>
                <li>
                  <a href="#">Puzzle</a>
                </li>
                <li>
                  <a href="#">War</a>
                </li>
                <li>
                  <a href="#">Army</a>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <div className="content_area animate-text-from-right">
                <p>
                  <b>Casual puzzles to epic adventures,</b> discover your next
                  mobile obsession. We craft unforgettable experiences for every
                  player.
                </p>
                <p>
                  We have a unique understanding of the digital games space,
                  connecting more than 7 million daily active players worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div
                id="listener-remote"
                className="games_info listener small pointer"
              >
                <div className="games_icon_left">
                  <div id="plate-remote" className="plate">
                    <img alt="" src="assets/images/om_noms_mini_garden.png" />
                  </div>
                </div>

                <div className="about_games">
                  <div className="game_title_icon">
                    <div className="icon_game">
                      <img
                        alt=""
                        src="assets/images/om_noms_mini_garden_icon.png"
                      />
                    </div>
                    <h5>500M Downloads</h5>
                    <h3>Om Noms Mini Garden</h3>
                  </div>
                  <ul className="games_type1">
                    <li>
                      <a href="#">Action</a>
                    </li>
                    <li>
                      <a href="#">Strategy</a>
                    </li>
                    <li>
                      <a href="#">Adventure</a>
                    </li>
                  </ul>
                  <p>
                    Casual puzzles to epic adventures, discover your next mobile
                    obsession. We craft unforgettable experiences for every
                    player.
                  </p>
                  <div className="store_links">
                    <a href="#">
                      <img alt="" src="assets/images/app_store_icon.png" />
                      App Store
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/play_store_icon.png" />
                      Play Store
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                id="listener-remote1"
                className="games_info listener small pointer"
              >
                <div className="about_games_right">
                  <div className="game_title_icon">
                    <div className="icon_game">
                      <img
                        alt=""
                        src="assets/images/color_water_sort_puzzle_game_icon.png"
                      />
                    </div>
                    <h5>30M Downloads</h5>
                    <h3>Color Water Sort Puzzle Game</h3>
                  </div>
                  <ul className="games_type1">
                    <li>
                      <a href="#">Puzzle</a>
                    </li>
                    <li>
                      <a href="#">Strategy</a>
                    </li>
                    <li>
                      <a href="#">Casual</a>
                    </li>
                  </ul>
                  <p>
                    Casual puzzles to epic adventures, discover your next mobile
                    obsession. We craft unforgettable experiences for every
                    player.
                  </p>
                  <div className="store_links">
                    <a href="#">
                      <img alt="" src="assets/images/app_store_icon.png" />
                      App Store
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/play_store_icon.png" />
                      Play Store
                    </a>
                  </div>
                </div>
                <div className="games_icon_right">
                  <div id="plate-remote1" className="plate">
                    <img
                      alt=""
                      src="assets/images/color_water_sort_puzzle_game.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div
                id="listener-remote2"
                className="games_info listener small pointer"
              >
                <div className="games_icon_left">
                  <div id="plate-remote2" className="plate">
                    <img alt="" src="assets/images/super_heroes.png" />
                  </div>
                </div>
                <div className="about_games">
                  <div className="game_title_icon">
                    <div className="icon_game">
                      <img alt="" src="assets/images/super_heroes_icon.png" />
                    </div>
                    <h5>10M Downloads</h5>
                    <h3>Super Heroes</h3>
                  </div>
                  <ul className="games_type1">
                    <li>
                      <a href="#">Puzzle</a>
                    </li>
                    <li>
                      <a href="#">Strategy</a>
                    </li>
                    <li>
                      <a href="#">Casual</a>
                    </li>
                  </ul>
                  <p>
                    Casual puzzles to epic adventures, discover your next mobile
                    obsession. We craft unforgettable experiences for every
                    player.
                  </p>
                  <div className="store_links">
                    <a href="#">
                      <img alt="" src="assets/images/app_store_icon.png" />
                      App Store
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/play_store_icon.png" />
                      Play Store
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div
                id="listener-remote3"
                className="games_info listener small pointer"
              >
                <div className="about_games_right">
                  <div className="game_title_icon">
                    <div className="icon_game">
                      <img
                        alt=""
                        src="assets/images/plane_game_air_combat_icon.png"
                      />
                    </div>
                    <h5>10M Downloads</h5>
                    <h3>Plane Game Air Combat</h3>
                  </div>
                  <ul className="games_type1">
                    <li>
                      <a href="#">Action</a>
                    </li>
                    <li>
                      <a href="#">Strategy</a>
                    </li>
                    <li>
                      <a href="#">Adventure</a>
                    </li>
                  </ul>
                  <p>
                    Casual puzzles to epic adventures, discover your next mobile
                    obsession. We craft unforgettable experiences for every
                    player.
                  </p>
                  <div className="store_links">
                    <a href="#">
                      <img alt="" src="assets/images/app_store_icon.png" />
                      App Store
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/play_store_icon.png" />
                      Play Store
                    </a>
                  </div>
                </div>
                <div className="games_icon_right">
                  <div id="plate-remote3" className="plate">
                    <img alt="" src="assets/images/plane_game_air_combat.png" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="our_awesome_studios">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <img
                className="icon_our_awesome_studios"
                alt=""
                src="assets/images/icon_our_awesome_studios.png"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h1>
                Our
                <img
                  className="awesome_studios_title-icon"
                  alt=""
                  src="assets/images/awesome_studios_title.png"
                />
                <br />
                <span>
                  Awesome
                  <br />
                  Studios
                </span>
              </h1>
            </div>
            <div className="col-6">
              <div className="content_area animate-text-from-right">
                <p>
                  <b>Where passion meets expertise.</b> Discover a collective of
                  world-class studios, each dedicated to pushing the boundaries
                  of mobile entertainment.
                </p>
                <p>
                  We have a unique understanding of the digital games space,
                  connecting more than 70 million daily active players
                  worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="logo_animation_area">
                <div className="sections">
                  <img
                    className="studios_icon_center"
                    alt=""
                    src="assets/images/studios_icon.png"
                  />
                  <div className="container-page flex-center">
                    <div className="contento one">
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/stickya-games.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/piggy-panda.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/dead-pixels.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/hmbl.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/omc-games.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/koko-zone.png"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="panels flex-center">
                    <section className="panel red">
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/stickya-games.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/piggy-panda.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/dead-pixels.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/hmbl.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/omc-games.png"
                        />
                      </div>
                      <div className="our_studios_logo">
                        <img
                          alt=""
                          src="https://gamedistrictpublishing.com/GD/assets/images/koko-zone.png"
                        />
                      </div>
                    </section>
                  </div>
                </div>

                <div className="clr"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>

        <div className="container">
          <ul className="accordion-list">
            <li className="active">
              <h3>Stickys Games</h3>
              <div className="answer" style={{ display: "block" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
              <div className="clr"></div>
            </li>
            <li>
              <h3>Rebel Games</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </li>
            <li>
              <h3>Dead pixels</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </li>
            <li>
              <h3>Play Spare</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
              <div className="clr"></div>
            </li>
            <li>
              <h3>Piggy Panda</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </li>
            <li>
              <h3>Tap Toy</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </li>
            <li>
              <h3>North Star</h3>
              <div className="answer">
                <div className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="studios_info">
                        <img alt="" src="assets/images/play_spare_logo.png" />
                        <p>
                          Something about this studio that can explain it’s
                          genre and expertise in a line or two.
                        </p>
                        <div className="store_links">
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/app_store_icon.png"
                            />
                            App Store
                          </a>
                          <a href="#">
                            <img
                              alt=""
                              src="assets/images/play_store_icon.png"
                            />
                            Play Store
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_1.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_2.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_3.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_4.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_5.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_6.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_7.png"
                        />
                      </div>
                      <div className="games_icons_row">
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_9.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_10.png"
                        />
                        <img
                          alt=""
                          src="assets/images/play_spare_game_icon_8.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
      {/* <div className="contact_us">
        <div className="container">
          <div className="row">
          <ContactUs/>
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/games_bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
}
