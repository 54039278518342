import { useState } from "react";

const ContactUs = ({ colClass = "col-4" }) => {
  const [payload, setPayload] = useState({
    name: null,
    email: null,
    category: null,
    aprox_budget: null,
    detail: null,
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    category: false,
    aprox_budget: false,
    detail: false,
  });
  const [submissionStatus, setSubmissionStatus] = useState("");

  const [fileError, setFileError] = useState(false);
  const [file, setFile] = useState(0);

  const submitProject = () => {
    let validated = true;

    Object.keys(errors).map((key) => {
      if (!payload[key]) {
        setErrors((pre) => ({ ...pre, [key]: true }));
        validated = false;
      }
    });

    if (!file) {
      setFileError((pre) => true);
      validated = false;
    }
    if (validated) {
      // show something e.g. like form is submitted
      setSubmissionStatus("success");
      setErrors({});
      // setTimeout(() => setSubmissionStatus(null), 3000);
    }

    // console.log(errors, payload);
    // if (validated) {
    //     let formData = new FormData();
    //     Object.keys(payload).map((key) => {
    //         if (key == 'date') {
    //             formData.append(key, formattedDateTime(payload[key]));
    //         } else {
    //             formData.append(key, payload[key]);
    //         }

    //     });
    // }
  };

  function validEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  function validateFile(e) {
    const fileInput = document.getElementById("fileInput");
    console.log(e);
    // fileInput.addEventListener('change', (e) => {
    const fileSize = e.target.files[0].size;
    const maxSize = 1024 * 1024 * 2; // 2MB
    if (fileSize > maxSize) {
      fileInput.value = ""; // reset the input value
    } else {
      setFileError((pre) => false);
    }
    // });
  }
  const [signUpMail, setSignUpMail] = useState("");
  const [signUpError, setSignUpError] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState("");
  function signUp() {
    debugger;
    console.log(signUpMail, validEmail(signUpMail));
    if (signUpMail && validEmail(signUpMail)) {
      setSignUpError(false);
      setSignUpSuccess("success");
    } else {
      setSignUpError(true);
    }
  }

  return (
    <>
      <style jsx="true">{`
        .submit-project:active {
          box-shadow: inset 0 0 7px rgba(255, 200, 0, 10);
          transform: translateY(1px);
        }
        @keyframes shake {
          0% {
            transform: translateX(0);
          }
          20% {
            transform: translateX(-2px);
          }
          40% {
            transform: translateX(2px);
          }
          60% {
            transform: translateX(-2px);
          }
          80% {
            transform: translateX(2px);
          }
          100% {
            transform: translateX(0);
          }
        }
      `}</style>
      <div className={colClass}>
        {colClass == "col-4" ? <h1>Contact us</h1> : null}
        <div className={colClass == "col-6" ? "contactinfo" : null}>
          <img className="boltmobile" alt="" src="assets/images/bolt.png" />
          <p>
            Team Game District is just a click away from you. Connect with us to
            get solutions to your business growth, market existence, and
            sustainability.
          </p>
          <div className="sign_up">
            <h4>Stay up to date on global innovations.</h4>
            <input
              style={
                signUpError && signUpSuccess !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
              onChange={(input) => {
                setSignUpMail(input.target.value);
                setSignUpError((pre) => (input.target.value ? false : true));
              }}
              className="email"
              type="text"
              placeholder="Enter your email"
              name="email"
            />
            {signUpError && signUpSuccess !== "success" && (
              <span
                style={{ color: "red", float: "left", animation: "shake 0.3s" }}
              >
                E-mail is required and must be a valid E-mail.
              </span>
            )}

            {signUpSuccess !== "success" ? (
              <input
                className="submit submit-project"
                onClick={signUp}
                type="button"
                value="Sign Up"
                name="submit"
              />
            ) : null}
            {signUpSuccess === "success" && (
              <div
                className="success-message"
                style={{
                  color: "green",
                  fontSize: "18px",
                  display: "flex",
                  float: "left",
                  height: "100%",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <span
                  style={{
                    animation: "slideIn 0.5s ease-out, fadeIn 0.5s ease-out",
                    display: "inline-block",
                  }}
                >
                  &nbsp;Submitted successfully! &nbsp;
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="25"
                  height="25"
                >
                  <style>
                    {`
                      @keyframes fadeIn {
                          from { opacity: 0; }
                          to { opacity: 1; }
                      }
                      @keyframes drawCheck {
                          from { stroke-dashoffset: 100; }
                          to { stroke-dashoffset: 0; }
                      }
                      @keyframes slideIn {
                          from { transform: translateX(-100%); }
                          to { transform: translateX(0); }
                      }
                      circle {
                          animation: fadeIn 0.5s ease-in-out;
                      }
                      path {
                          stroke-dasharray: 100;
                          stroke-dashoffset: 100;
                          animation: drawCheck 0.5s ease-in-out 0.5s forwards;
                      }
                  `}
                  </style>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="white"
                    stroke="#4CAF50"
                    stroke-width="5"
                  />
                  <path
                    d="M30 50 L45 65 L70 40"
                    stroke="#4CAF50"
                    stroke-width="8"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={colClass}>
        <div className="just_play">
          {colClass == "col-4" ? (
            <div className="just_play_text">
              <a href="#" className="circle-btn welcome-img-btn">
                <span className="" id="text">
                  JUST PLAY GAMES. JUST MAKE GAMES
                </span>
              </a>
              <img alt="" src="assets/images/just_play_arrow.png" />
            </div>
          ) : null}

          <div className="submit_project">
            <input
              className="text"
              style={
                errors && errors.name && submissionStatus !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
              onChange={(input) => {
                setPayload((pre) => ({
                  ...pre,
                  name: input.target.value !== "" ? input.target.value : null,
                }));
                setErrors((pre) => ({
                  ...pre,
                  name: input.target.value ? false : true,
                }));
              }}
              type="text"
              name="name"
              placeholder="Name"
            />
            <input
              className="text"
              style={
                errors && errors.email && submissionStatus !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
              onChange={(input) => {
                setPayload((pre) => ({
                  ...pre,
                  email:
                    input.target.value !== "" && validEmail(input.target.value)
                      ? input.target.value
                      : null,
                }));
                setErrors((pre) => ({
                  ...pre,
                  email:
                    input.target.value !== "" && validEmail(input.target.value)
                      ? false
                      : true,
                }));
              }}
              type="email"
              placeholder="Email Address"
              name="email"
            />
            <div
              className="select"
              style={
                errors && errors.category && submissionStatus !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
            >
              <select
                onChange={(input) => {
                  setPayload((pre) => ({
                    ...pre,
                    category:
                      input.target.value !== "0" ? input.target.value : null,
                  }));
                  setErrors((pre) => ({
                    ...pre,
                    category: input.target.value ? false : true,
                  }));
                }}
              >
                <option value="0">Category</option>
                <option>Category1</option>
                <option>Category2</option>
                <option>Category3</option>
                <option>Category4</option>
                <option>Category5</option>
                <option>Category6</option>
              </select>
            </div>
            <div
              className="select"
              style={
                errors && errors.aprox_budget && submissionStatus !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
            >
              <select
                onChange={(input) => {
                  setPayload((pre) => ({
                    ...pre,
                    aprox_budget:
                      input.target.value !== "0" ? input.target.value : null,
                  }));
                  setErrors((pre) => ({
                    ...pre,
                    aprox_budget: input.target.value ? false : true,
                  }));
                }}
              >
                <option value="0">Approx Budget</option>
                <option>Approx Budget</option>
                <option>Approx Budget</option>
                <option>Approx Budget</option>
                <option>Approx Budget</option>
              </select>
            </div>
            <textarea
              style={
                errors && errors.detail && submissionStatus !== "success"
                  ? { border: "1px solid red" }
                  : {}
              }
              onChange={(input) => {
                setPayload((pre) => ({
                  ...pre,
                  detail:
                    input.target.value !== "0" ? input.target.value : null,
                }));
                setErrors((pre) => ({
                  ...pre,
                  detail: input.target.value ? false : true,
                }));
              }}
              className="textarea"
              placeholder="Project Details"
            ></textarea>
            <div>
              <input
                onChange={(input) => {
                  setFile(() => {
                    return input.target.files[0] ? true : false;
                  });
                  setErrors(() => {
                    return input.target.files[0] && validateFile(input)
                      ? true
                      : false;
                  });
                }}
                className="file"
                type="file"
                name="file"
                placeholder="Browse"
                id="fileInput"
              />
              {fileError && (
                <span
                  style={{
                    color: "red",
                    float: "left",
                    animation: "shake 0.3s",
                  }}
                >
                  File is required and must not exceed the size limit of 2mb.
                </span>
              )}
            </div>
            {!submissionStatus && (
              <input
                className="submit submit-project"
                onClick={submitProject}
                type="button"
                value="Submit Project"
                name="submit"
              />
            )}

            {submissionStatus === "success" && (
              <div
                className="success-message"
                style={{
                  color: "green",
                  fontSize: "18px",
                  display: "flex",
                  float: "left",
                  height: "100%",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <span
                  style={{
                    animation: "slideIn 0.5s ease-out, fadeIn 0.5s ease-out",
                    display: "inline-block",
                  }}
                >
                  &nbsp;Submitted successfully! &nbsp;
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="25"
                  height="25"
                >
                  <style>
                    {`
                      @keyframes fadeIn {
                          from { opacity: 0; }
                          to { opacity: 1; }
                      }
                      @keyframes drawCheck {
                          from { stroke-dashoffset: 100; }
                          to { stroke-dashoffset: 0; }
                      }
                      @keyframes slideIn {
                          from { transform: translateX(-100%); }
                          to { transform: translateX(0); }
                      }
                      circle {
                          animation: fadeIn 0.5s ease-in-out;
                      }
                      path {
                          stroke-dasharray: 100;
                          stroke-dashoffset: 100;
                          animation: drawCheck 0.5s ease-in-out 0.5s forwards;
                      }
                  `}
                  </style>
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill="white"
                    stroke="#4CAF50"
                    stroke-width="5"
                  />
                  <path
                    d="M30 50 L45 65 L70 40"
                    stroke="#4CAF50"
                    stroke-width="8"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
            {/* or you can add an animation here */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
