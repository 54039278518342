import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";
const Pixels = () => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $(".item").click(function () {
      $(this).addClass("active").siblings(".item").removeClass("active");
      $(this)
        .next()
        .show()
        .animate({ width: "36%" })
        .siblings(".info")
        .animate({ width: 0 }, function () {
          $(this).hide();
        });
    });

    var Flip;
    Flip = class Flip {
      constructor(el) {
        this.el = el;
        this.el = $(this.el);
        this.currentStep = 0;
        console.log("Created new Flip");
        $(".next").on("click", $(this.next, this));
      }

      next() {
        var currentStepEl, nextStepEl, nextStepNum;
        nextStepNum = this.currentStep + 1;
        currentStepEl = this.el?.find(`.step${this.currentStep}`);
        nextStepEl = this.el?.find(`.step${nextStepNum}`);
        if (nextStepEl?.length) {
          console.log("we found the next step", nextStepEl);
          currentStepEl.prev().removeClass("down");
          currentStepEl.removeClass("set");
          currentStepEl.addClass("down");
          nextStepEl.addClass("set");
          nextStepEl.removeClass("down");
          nextStepEl.next().removeClass("down");
          return this.currentStep++;
        } else {
          // reset to 0
          this.el?.find(".step").removeClass("set");
          this.el?.find(`.step${this.currentStep}`).addClass("down");
          this.el
            ?.find(".step")
            .not(`.step${this.currentStep}`)
            .removeClass("down");
          this.currentStep = -1;
          if (this.el) {
            return this.next();
          }
        }
      }
    };

    function start_flip() {
      // alert('here');
      var f;
      f = new Flip(document.getElementById("flipper"));
      return setInterval(function () {
        return f.next();
      }, 1500);
    }

    start_flip();
  }, []);
  const _openNav = () => {
    document.getElementById("content_menu").style.width = "250px";
  };

  const _closeNav = () => {
    document.getElementById("content_menu").style.width = "0";
  };
  return (
    <>
      <Helmet>
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        <link rel="stylesheet" href="assets/slider/owl.carousel.min.css" />
        <link rel="stylesheet" href="assets/slider/owl.theme.default.min.css" />

        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link type="text/css" href="assets/css/programs.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        {/* <script src="assets/slider/jquery.mousewheel.min.js"></script> */}
        <script src="assets/animation/plugins.js"></script>

        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
                    function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
                        setTimeout(() => {
                               new WOW().init();
                                    document.addEventListener("mousemove", parallax);
                                    function parallax(e) {
                                        document.querySelectorAll(".object").forEach(function (move) {

                                            var moving_value = move.getAttribute("data-value");
                                            var x = (e.clientX * moving_value) / 250;
                                            var y = (e.clientY * moving_value) / 250;

                                            move.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
                                        });
							}
                            var TxtType = function (el, toRotate, period) {
                            this.toRotate = toRotate;
                            this.el = el;
                            this.loopNum = 0;
                            this.period = parseInt(period, 10) || 2000;
                            this.txt = '';
                            this.tick();
                            this.isDeleting = false;
			            };

                        TxtType.prototype.tick = function () {
                        var i = this.loopNum % this.toRotate.length;
                        var fullTxt = this.toRotate[i];

                        if (this.isDeleting) {
                            this.txt = fullTxt.substring(0, this.txt.length - 1);
                        } else {
                            this.txt = fullTxt.substring(0, this.txt.length + 1);
                        }

                        this.el.innerHTML = '<span className="wrap">' + this.txt + '</span>';

                        var that = this;
                        var delta = 200 - Math.random() * 100;

                        if (this.isDeleting) { delta /= 2; }

                        if (!this.isDeleting && this.txt === fullTxt) {
                            delta = this.period;
                            this.isDeleting = true;
                        } else if (this.isDeleting && this.txt === '') {
                            this.isDeleting = false;
                            this.loopNum++;
                            delta = 500;
                        }

                        setTimeout(function () {
                            that.tick();
                        }, delta);
			        };

			window.onload = function () {
				var elements = document.getElementsByClassName('typewrite');
				for (var i = 0; i < elements.length; i++) {
					var toRotate = elements[i].getAttribute('data-type');
					var period = elements[i].getAttribute('data-period');
					if (toRotate) {
						new TxtType(elements[i], JSON.parse(toRotate), period);
					}
				}
				// INJECT CSS
				var css = document.createElement("style");
				css.type = "text/css";
				css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000}";
				document.body.appendChild(css);
			};    
        
           fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
           fadeIn(".animate-text-from-bottom", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
           const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'

           const toptext = document.getElementById('toptext')
    const toptextrotate = new CircleType(toptext).radius(50)
    toptext.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'

            },3000)
  
            `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      {loader ? (
        <div className="loaderLayout">
          <img alt="" src="assets/images/logo.png" className="loaderLogo" />
        </div>
      ) : null}
      <div className="top_home_area">
        <div className="header">
          <div className="logo">
            <a href="index.html">
              <img alt="" src="assets/images/logo_w.png" />
            </a>
          </div>
          <div className="main_menu">
            <ul>
              <li className="active">
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/games">Games</a>
              </li>
              <li>
                <a href="/gdhub">publishing</a>
              </li>
              <li>
                <a href="/tools">Growth Sandbox</a>
              </li>
              <li>
                <a href="/ip">IP</a>
              </li>
              <li>
                <a href="/blogs">Blogs</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="mobile_menu">
            <span onClick={() => _openNav()}>&#9776;</span>
            <div id="content_menu" className="mobilemenu">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={() => _closeNav()}
              >
                &times;
              </a>
              <Link to="/">Home</Link>
              <Link to="/About">About</Link>
              <Link to="/Games">Games</Link>
              <Link to="/gdhub">publishing</Link>
              <Link to="/tools">Growth Sandbox</Link>
              <Link to="/ip">IP</Link>
              <Link to="/blogs">Blogs</Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>

          <div className="clr"></div>
        </div>

        <div className="banner_programs">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner_programs_top fadein_bottom_20">
                  <img
                    data-value="2"
                    className="banner_top_1 object"
                    alt=""
                    src="assets/images/banner_top_1.png"
                  />
                  <img
                    data-value="4"
                    className="banner_top_logo object"
                    alt=""
                    src="assets/images/banner_top_logo.png"
                  />
                  <img
                    data-value="-2"
                    className="banner_top_title object"
                    alt=""
                    src="assets/images/banner_top_title.png"
                  />
                  <img
                    data-value="2"
                    className="banner_top_3 object"
                    alt=""
                    src="assets/images/banner_top_3.png"
                  />
                  <img
                    data-value="6"
                    className="banner_top_4 object"
                    alt=""
                    src="assets/images/banner_top_4.png"
                  />
                  <img
                    data-value="-4"
                    className="banner_top_5 object"
                    alt=""
                    src="assets/images/banner_top_5.png"
                  />

                  <img
                    data-value="2"
                    className="banner_top_2 object"
                    alt=""
                    src="assets/images/banner_top_2.png"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
          </div>
          <div className="clr"></div>

          <div className="banner_links">
            <div className="just_play2">
              <div className="just_play_text">
                <a href="#" className="circle-btn welcome-img-btn">
                  <span id="toptext">
                    JUST PLAY GAMES. JUST MAKE GAMES{" "}
                  </span>
                </a>
                <img alt="" src="assets/images/just_play_arrow_white.png" />
              </div>
              <div className="clr"></div>
            </div>

            <div className="right_text">
              <span className="text2">how cool are we</span>
              <span className="text">Check out</span>
            </div>
            <div className="social_icons_banner">
              <a href="#">
                <img alt="" src="assets/images/icon_instagram_banner.png" />
              </a>
              <a href="#">
                <img alt="" src="assets/images/icon_inn_banner.png" />
              </a>
            </div>
            <div className="clr"></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="banner_programs_bottom fadein_bottom_23">
                  <div className="banner_programs_row">
                    <img
                      className="top_line_ing_1"
                      alt=""
                      src="assets/images/top_line_ing_1.png"
                    />
                    <img
                      className="top_line_ing_2"
                      alt=""
                      src="assets/images/top_line_ing_2.png"
                    />
                    <img
                      className="top_line_ing_3"
                      alt=""
                      src="assets/images/top_line_ing_3.png"
                    />
                    <img
                      className="top_line_ing_4"
                      alt=""
                      src="assets/images/top_line_ing_4.png"
                    />
                    <img
                      className="top_line_ing_5"
                      alt=""
                      src="assets/images/top_line_ing_5.png"
                    />
                    <img
                      className="top_line_ing_6"
                      alt=""
                      src="assets/images/top_line_ing_6.png"
                    />
                  </div>
                  <div className="banner_programs_row_center fadein_bottom_23">
                    <img
                      className="center_line_ing_1"
                      alt=""
                      src="assets/images/center_line_ing_1.png"
                    />
                    <img
                      className="center_line_ing_2"
                      alt=""
                      src="assets/images/center_line_ing_2.png"
                    />
                    <img
                      className="center_line_ing_3"
                      alt=""
                      src="assets/images/center_line_ing_3.png"
                    />
                    <img
                      className="center_line_ing_4"
                      alt=""
                      src="assets/images/center_line_ing_4.png"
                    />
                    <img
                      className="center_line_ing_5"
                      alt=""
                      src="assets/images/center_line_ing_5.png"
                    />
                  </div>
                  <div className="banner_programs_row_bottom fadein_bottom_23">
                    <img
                      className="bottom_line_ing_1"
                      alt=""
                      src="assets/images/bottom_line_ing_1.png"
                    />
                    <img
                      className="bottom_line_ing_2"
                      alt=""
                      src="assets/images/bottom_line_ing_2.png"
                    />
                    <img
                      className="bottom_line_ing_3"
                      alt=""
                      src="assets/images/bottom_line_ing_3.png"
                    />
                    <img
                      className="bottom_line_ing_4"
                      alt=""
                      src="assets/images/bottom_line_ing_4.png"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="pixls_program">
        <div className="pixls_program_content">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img
                  className="pixls_program_img"
                  alt=""
                  src="assets/images/pixls_program.png"
                />
              </div>
              <div className="col-6">
                <div className="pixls_program_info">
                  <div className="pixls_program_content_icon">
                    <img
                      data-value="2"
                      className="pixls_icon1 object wow bounceInUp"
                      data-wow-delay="0.5s"
                      alt=""
                      src="assets/images/pixls_program_content_icon1.png"
                    />
                    <img
                      data-value="-4"
                      className="pixls_icon2 object wow bounceInUp"
                      data-wow-delay="0.5s"
                      alt=""
                      src="assets/images/pixls_program_content_icon2.png"
                    />
                  </div>

                  <p className="animate-text-from-right">
                    In this ever-evolving world of gaming and artistry,
                    <b>GAME DISTRICT</b> crafted a training program with one
                    goal in mind:
                    <br />
                    To ignite your passion and empower you for an exciting
                    career in the dynamic realm of gaming.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="clr"></div>
      <div className="pixls_program_animation2">
        <div className="marquee">
          <div className="marquee--inner">
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                TRAINING PROGRAM
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                TRAINING PROGRAM
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                TRAINING PROGRAM
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                TRAINING PROGRAM
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                TRAINING PROGRAM
              </div>
            </span>
            <span>
              <div>
                <img alt="" src="assets/images/doots_program.png" />
                bridging imagination & innovation
              </div>
            </span>
          </div>
        </div>
      </div>

      <div className="program_vision_area">
        <div className="container">
          <div className="row">
            <div className="col-5">
              <div className="program_vision_title">
                <h1>
                  <span className="extralight">V</span>
                  <span className="regular">IS</span>
                  <span className="bold">IO</span>N
                  <img
                    className=" wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                    alt=""
                    src="assets/images/program_vision_title_star.png"
                  />
                </h1>
                <img
                  className="program_vision_map"
                  alt=""
                  src="assets/images/program_vision_map.png"
                />
              </div>
            </div>
            <div className="col-7">
              <div className="program_vision_info animate-text-from-right">
                <p>
                  Our Vision is to bridging the resource scarcity gap by{" "}
                  <span className="program_vision_info_img_1">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_1.png"
                    />
                  </span>{" "}
                  a new generation of skilled professionals who will shape the
                  gaming industry's destiny in Pakistan.
                </p>

                <p>
                  Through our{" "}
                  <span className="program_vision_info_img_2">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_2.png"
                    />
                  </span>{" "}
                  we aim to empower aspiring individuals with the knowledge,
                  skills, and passion needed to create world-class mobile games.
                </p>

                <p>
                  By providing comprehensive training, mentorship, and
                  opportunities, we will foster a{" "}
                  <span className="program_vision_info_img_3">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_3.png"
                    />
                  </span>{" "}
                  of game developers, artists, designers, and enthusiasts who
                  will drive the industry's growth.{" "}
                  <span className="program_vision_info_img_4">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_4.png"
                    />
                  </span>{" "}
                </p>

                <p>
                  In this transformative journey, we are committed to turn
                  scarcity into abundance and challenges into{" "}
                  <span className="program_vision_info_img_6">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_6.png"
                    />
                  </span>{" "}
                  <span className="program_vision_info_img_5">
                    <img
                      alt=""
                      src="assets/images/program_vision_info_img_5.png"
                    />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="program_plan">
        <div className="container">
          <div className="row">
            <div className="col-5">
              <div className="program_plan_title">
                <h1>
                  <span className="thin">Pro</span>
                  <img
                    className="program_plan_title_icon  wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                    alt=""
                    src="assets/images/program_plan_title_icon.png"
                  />
                  <img
                    className="program_plan_title_icon2  wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                    alt=""
                    src="assets/images/program_plan_title_icon2.png"
                  />
                  <br />
                  <span className="thin">gram</span>
                  <br />
                  plan
                </h1>
                <img
                  className="program_plan_img "
                  alt=""
                  src="assets/images/program_plan_img.png"
                />
              </div>
            </div>
            <div className="col-7">
              <div className="program_plan_info">
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg1"> Program Premis</h3>
                  <p>Game Development & Game Art</p>
                </div>
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg4">Duration</h3>
                  <p>3-4 Months</p>
                </div>
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg7">No.of Participent</h3>
                  <p>On-boarded 30 trainees in batch 1 of GD-PIXLS</p>
                </div>
              </div>
              <div className="program_plan_info">
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg2">Program Scope</h3>
                  <p>Beginners Level (fresh graduates)</p>
                </div>
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg5">Fee/Stipend</h3>
                  <p>Monthly stipend during the training period</p>
                </div>
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg8">Training Methodology</h3>
                  <p>
                    Hands-on learning, Workshops, & Lectures by the
                    professionals
                  </p>
                </div>
              </div>
              <div className="program_plan_info">
                <div className="program_plan_details margin_top fadein_bottom_23">
                  <h3 className="bg3">Participation Criteria</h3>
                  <p>
                    Anyone with basic programming skills and good artistic
                    skills.
                  </p>
                </div>
                <div className="program_plan_details fadein_bottom_23">
                  <h3 className="bg6">Participation Criteria</h3>
                  <p>
                    Participants will enjoy special lectures and training
                    sessions led by experienced industry professionals.
                  </p>
                  <p>
                    These sessions provide unique insights into the gaming
                    industry, share best practices, and offer real-world
                    experiences.
                  </p>
                </div>
                <img
                  className="star_participation_criteria  wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                  alt=""
                  src="assets/images/star_participation_criteria.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>

        <div className="our_partners">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>
                  <span>Our</span> Partners{" "}
                  <img
                    className="our_partners_icon"
                    alt=""
                    src="assets/images/our_partners_icon.png"
                  />
                </h1>
                <img
                  className="partners"
                  alt=""
                  src="assets/images/partners.png"
                />
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
        <div className="achievements">
          <div className="container">
            <div className="row">
              <div className="col-5">
                <h2>
                  ACHIE
                  <br />
                  <span className="margin_left">VEM</span>
                  <br />
                  <span className="margin_left2">ENTS</span>
                </h2>
                <img
                  className="achievements_star wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                  alt=""
                  src="assets/images/achievements_star.png"
                />
              </div>
              <div className="col-7 fadein_bottom_23">
                <img alt="" src="assets/images/achievements_1.png" />
                <img alt="" src="assets/images/achievements_2.png" />
                <img alt="" src="assets/images/achievements_3.png" />
                <img alt="" src="assets/images/achievements_4.png" />
                <img alt="" src="assets/images/achievements_5.png" />
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="achievements_img">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <img
                    className="achievements_img_1"
                    alt=""
                    src="assets/images/achievements_img_1.png"
                  />
                </div>
                <div className="col-7">
                  <img
                    className="achievements_img_2"
                    alt=""
                    src="assets/images/achievements_img_2.png"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-5">
                  <img
                    className="achievements_img_3"
                    alt=""
                    src="assets/images/achievements_img_3.png"
                  />
                </div>
                <div className="col-7">
                  <img
                    className="achievements_img_4"
                    alt=""
                    src="assets/images/achievements_img_4.png"
                  />
                </div>
              </div>
            </div>

            <div className="future_plans">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <img
                      className="future_plans_img"
                      alt=""
                      src="assets/images/future_plans.png"
                    />
                    <p>
                      Expanding our training program across regions to address
                      talent shortfalls in industries,such as:
                    </p>

                    <div className="future_plans_info">
                      <img
                        className="future_plans_icon_right"
                        alt=""
                        src="assets/images/future_plans_icon_right.png"
                      />
                      <img
                        className="fadein_bottom_23"
                        alt=""
                        src="assets/images/future_plans_info.png"
                      />
                      <img
                        className="future_plans_icon_left"
                        alt=""
                        src="assets/images/future_plans_icon_left.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="clr"></div>
            </div>
          </div>
        </div>

        <div className="contact_us_programs">
          <div className="contact_us_programs_inner">
            <div className="container">
              <div className="row">
                <div className="col-8">
                  <div className="hardworking">
                    <p>
                      We’re hardworking
                      <br /> yet fun-loving
                    </p>
                  </div>
                  <h1 className="">
                    {" "}
                    <span id="flipper" className="flip">
                      <span className="step step0 set">We love</span>
                      <span className="step step1">You love</span>
                      <span className="step step2">We love</span>
                      <span className="step step3">You love</span>
                      <span className="step step4">We love</span>
                      <span className="step step5">You love</span>
                    </span>{" "}
                    <span className="righttext wow bounceInRight">
                      We love
                      <br />
                      what
                      <br />
                      we do
                      <br />
                      what
                      <br />
                      we love
                    </span>
                  </h1>
                  <h2>
                    <img alt="" src="assets/images/arrow_awesome.png" />
                    awesome
                  </h2>
                  <h3>games</h3>
                </div>
                <div className="col-4">
                  <div className="bolt fadein_bottom_12">
                    <img alt="" src="assets/images/bolt.png" />
                  </div>
                </div>
              </div>

              <div className="just_make_games">
                <div className="right_text">
                  <span className="text2">how cool are we</span>
                  <span className="text">Check out</span>
                </div>
                <div className="social_icons_footer">
                  <a href="#">
                    <img alt="" src="assets/images/icon_instagram_footer.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/icon_inn_footer.png" />
                  </a>
                </div>
                <div className="just_play_text">
                  <a href="#" className="circle-btn welcome-img-btn">
                    <span className="circle-btn__text" id="text">
                      JUST PLAY GAMES. JUST MAKE GAMES{" "}
                    </span>
                  </a>
                  <img alt="" src="assets/images/just_play_arrow.png" />
                </div>
              </div>
            </div>
            <div className="contactus_programs_info">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h1>Contact us</h1>
                  </div>
                </div>
                <div className="row">
                  <ContactUs colClass="col-6" />
                  {/* <div className="col-6">
                                        <div className="contactinfo">
                                            <p>Team Game District is just a click away from you. Connect with us to get
                                                solutions to your business growth, market existence, and sustainability.</p>
                                            <div className="sign_up">
                                                <h4>Stay up to date on global innovations.</h4>
                                                <input className="email" type="email" placeholder="Enter your email" name="email" />
                                                <input className="submit" type="submit" value="Sign Up" name="submit" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="submit_project">
                                            <input className="text" type="text" name="name" placeholder="Name" />
                                            <input className="text" type="email" placeholder="Email Address" name="email" />
                                            <div className="select">
                                                <select>
                                                    <option>Category</option>
                                                    <option>Category1</option>
                                                    <option>Category2</option>
                                                    <option>Category3</option>
                                                    <option>Category4</option>
                                                    <option>Category5</option>
                                                    <option>Category6</option>
                                                </select>
                                            </div>
                                            <div className="select">
                                                <select>
                                                    <option>Approx Budget</option>
                                                    <option>Approx Budget</option>
                                                    <option>Approx Budget</option>
                                                    <option>Approx Budget</option>
                                                    <option>Approx Budget</option>
                                                </select>
                                            </div>
                                            <textarea className="textarea" placeholder="Project Details"></textarea>
                                            <input className="file" type="file" name="file" placeholder="Browse" />
                                            <input className="submit" type="submit" value="Submit Project" name="submit" />
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className="clr"></div>
            </div>

            <div className="clr"></div>
          </div>
        </div>

        <div className="footer">
          <div className="footer_area_programs">
            <div className="container">
              <div className="row">
                <div className="col-6 animate-text-from-bottom">
                  <div className="email">hello@gdbolt.co</div>
                  <div className="address">
                    44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                  </div>
                  <div className="social_icons">
                    <a href="#">
                      <img alt="" src="assets/images/inn.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/facebook.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/instagram.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/twitter.png" />
                    </a>
                  </div>
                </div>
                <div className="col-6 animate-text-from-bottom">
                  <ul className="footer_menu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/games">Games</a>
                    </li>
                    <li>
                      <a href="/gdhub">GD Hub</a>
                    </li>
                    <li>
                      <a href="/ip">IP</a>
                    </li>
                    <li>
                      <a href="/blogs">Blogs</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
          <div className="clr"></div>
        </div>
      </div>
    </>
  );
};

export default Pixels;
