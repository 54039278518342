import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import { Link } from "react-router-dom";
const Hackathon = () => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $(".item").click(function () {
      $(this).addClass("active").siblings(".item").removeClass("active");
      $(this)
        .next()
        .show()
        .animate({ width: "36%" })
        .siblings(".info")
        .animate({ width: 0 }, function () {
          $(this).hide();
        });
    });

    var Flip;
    Flip = class Flip {
      constructor(el) {
        this.el = el;
        this.el = $(this.el);
        this.currentStep = 0;
        console.log("Created new Flip");
        $(".next").on("click", $(this.next, this));
      }

      next() {
        var currentStepEl, nextStepEl, nextStepNum;
        nextStepNum = this.currentStep + 1;
        currentStepEl = this.el?.find(`.step${this.currentStep}`);
        nextStepEl = this.el?.find(`.step${nextStepNum}`);
        if (nextStepEl?.length) {
          console.log("we found the next step", nextStepEl);
          currentStepEl.prev().removeClass("down");
          currentStepEl.removeClass("set");
          currentStepEl.addClass("down");
          nextStepEl.addClass("set");
          nextStepEl.removeClass("down");
          nextStepEl.next().removeClass("down");
          return this.currentStep++;
        } else {
          // reset to 0
          this.el?.find(".step").removeClass("set");
          this.el?.find(`.step${this.currentStep}`).addClass("down");
          this.el
            ?.find(".step")
            .not(`.step${this.currentStep}`)
            .removeClass("down");
          this.currentStep = -1;
          if (this.el) {
            return this.next();
          }
        }
      }
    };

    function start_flip() {
      // alert('here');
      var f;
      f = new Flip(document.getElementById("flipper"));
      return setInterval(function () {
        return f.next();
      }, 1500);
    }

    start_flip();
  }, []);
  const _openNav = () => {
    document.getElementById("content_menu").style.width = "250px";
  };

  const _closeNav = () => {
    document.getElementById("content_menu").style.width = "0";
  };
  return (
    <>
      <Helmet>
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        <link rel="stylesheet" href="assets/slider/owl.carousel.min.css" />
        <link rel="stylesheet" href="assets/slider/owl.theme.default.min.css" />

        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link type="text/css" href="assets/css/programs.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        {/* <script src="assets/slider/jquery.mousewheel.min.js"></script> */}
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
                    function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
                        setTimeout(() => {
                               new WOW().init();
                                    document.addEventListener("mousemove", parallax);
                                    function parallax(e) {
                                        document.querySelectorAll(".object").forEach(function (move) {

                                            var moving_value = move.getAttribute("data-value");
                                            var x = (e.clientX * moving_value) / 250;
                                            var y = (e.clientY * moving_value) / 250;

                                            move.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
                                        });
							}
                            var TxtType = function (el, toRotate, period) {
                            this.toRotate = toRotate;
                            this.el = el;
                            this.loopNum = 0;
                            this.period = parseInt(period, 10) || 2000;
                            this.txt = '';
                            this.tick();
                            this.isDeleting = false;
			            };

                        TxtType.prototype.tick = function () {
                        var i = this.loopNum % this.toRotate.length;
                        var fullTxt = this.toRotate[i];

                        if (this.isDeleting) {
                            this.txt = fullTxt.substring(0, this.txt.length - 1);
                        } else {
                            this.txt = fullTxt.substring(0, this.txt.length + 1);
                        }

                        this.el.innerHTML = '<span className="wrap">' + this.txt + '</span>';

                        var that = this;
                        var delta = 200 - Math.random() * 100;

                        if (this.isDeleting) { delta /= 2; }

                        if (!this.isDeleting && this.txt === fullTxt) {
                            delta = this.period;
                            this.isDeleting = true;
                        } else if (this.isDeleting && this.txt === '') {
                            this.isDeleting = false;
                            this.loopNum++;
                            delta = 500;
                        }

                        setTimeout(function () {
                            that.tick();
                        }, delta);
			        };

			window.onload = function () {
				var elements = document.getElementsByClassName('typewrite');
				for (var i = 0; i < elements.length; i++) {
					var toRotate = elements[i].getAttribute('data-type');
					var period = elements[i].getAttribute('data-period');
					if (toRotate) {
						new TxtType(elements[i], JSON.parse(toRotate), period);
					}
				}
				// INJECT CSS
				var css = document.createElement("style");
				css.type = "text/css";
				css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000}";
				document.body.appendChild(css);
			};    
        
           fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
           fadeIn(".fadein_bottom_20", { opacity: 0, y: 50 }, { stagger: 0.25 });
           fadeIn(".animate-text-from-bottom", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });

           
                                    document.addEventListener("mousemove", parallax);
                                    function parallax(e) {
                                        document.querySelectorAll(".object").forEach(function (move) {

                                            var moving_value = move.getAttribute("data-value");
                                            var x = (e.clientX * moving_value) / 250;
                                            var y = (e.clientY * moving_value) / 250;

                                            move.style.transform = "translateX(" + x + "px) translateY(" + y + "px)";
                                        });
              }
                            

const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
    const imageParallax = document.querySelectorAll(".parallax-image");

		if (imageParallax.length > 0) {
			imageParallax.forEach(function (element) {
				const wrapper = document.createElement("div");
				wrapper.className = "parallax-image-wrap";

				const innerWrapper = document.createElement("div");
				innerWrapper.className = "parallax-image-inner";

				element.parentNode.insertBefore(wrapper, element);
				wrapper.appendChild(innerWrapper);
				innerWrapper.appendChild(element);

				wrapper.style.overflow = "hidden";

				const animImageParallax = element;
				const imgParallaxWrapper = wrapper;
				const innerWrap = innerWrapper;

				const tlImageParallax = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						onEnter: animImgParallaxRefresh,
					},
				});

				tlImageParallax.to(animImageParallax, {
					yPercent: 35,
					ease: "none",
				});

				// Function to refresh the scroll trigger
				function animImgParallaxRefresh() {
					tlImageParallax.scrollTrigger.refresh();
				}

				// Animation setup for zoom-in effect
				const tlZoomIn = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top 100%",
					},
				});

				tlZoomIn.from(innerWrap, {
					duration: 1.5,
					opacity: 0,
					scale: 1.2,
					ease: "power2.out",
					clearProps: "all",
				});
			});
		}

            },3000)
  
            `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      {loader ? (
        <div className="loaderLayout">
          <img alt="" src="assets/images/logo.png" className="loaderLogo" />
        </div>
      ) : null}
      <div className="programs_hackathon_top_area">
        <div className="header">
          <div className="logo">
            <a href="/">
              <img alt="" src="assets/images/logo_w.png" />
            </a>
          </div>
          <div className="main_menu">
            <ul>
              <li className="active">
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/games">Games</a>
              </li>
              <li>
                <a href="/gdhub">publishing</a>
              </li>
              <li>
                <a href="#">Growth Sandbox</a>
                <ul>
                  <li>
                    <h3>Tools</h3>
                  </li>
                  <li>
                    <a href="/tools">Applifier</a>
                  </li>
                  <li>
                    <a href="/tools">GDI</a>
                  </li>
                  <li>
                    <h3>Programs</h3>
                  </li>
                  <li>
                    <a href="/pixls">Pixls</a>
                  </li>
                  <li>
                    <a href="/hackathon">Hackathon</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/ip">IP</a>
              </li>
              <li>
                <a href="/blogs">Blogs</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="mobile_menu">
            <span onClick={() => _openNav()}>&#9776;</span>
            <div id="content_menu" className="mobilemenu">
              <a
                href="javascript:void(0)"
                className="closebtn"
                onClick={() => _closeNav()}
              >
                &times;
              </a>
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/games">Games</Link>
              <Link to="/gdhub">publishing</Link>
              <Link to="/tools">Growth Sandbox</Link>
              <Link to="/ip">IP</Link>
              <Link to="/blogs">Blogs</Link>
              <Link to="/contact">Contact</Link>
            </div>
          </div>

          <div className="clr"></div>
        </div>

        <div className="together_towards_tomorrow">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="together_towards_tomorrow_top fadein_bottom_20">
                  <img
                    data-value="2"
                    className="be_bold_make_history object"
                    alt=""
                    src="assets/images/be_bold_make_history.png"
                  />

                  <img
                    data-value="6"
                    className="together_towards_tomorrow_icon_right_top object"
                    alt=""
                    src="assets/images/together_towards_tomorrow_icon_right_top.png"
                  />
                  <img
                    data-value="-2"
                    className="together_towards_tomorrow2 object"
                    alt=""
                    src="assets/images/together_towards_tomorrow.png"
                  />

                  <img
                    data-value="6"
                    className="together_towards_tomorrow_icon_right_bottom object"
                    alt=""
                    src="assets/images/together_towards_tomorrow_icon_right_bottom.png"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12"></div>
            </div>
          </div>
          <div className="clr"></div>

          <div className="banner_links_just_play">
            <div className="just_play_together_towards_tomorrow">
              <div className="just_play_text">
                <a href="#" className="circle-btn welcome-img-btn">
                  <span className="circle-btn__text">
                    JUST PLAY GAMES. JUST MAKE GAMES{" "}
                  </span>
                </a>
                <img alt="" src="assets/images/just_play_arrow_white.png" />
              </div>
              <div className="clr"></div>
            </div>
            <div className="clr"></div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-7">
                <h2>
                  Why Hackathon?{" "}
                  <img
                    alt=""
                    src="assets/images/why_hackathon_title_icon.png"
                  />
                </h2>
                <p className="animate-text-from-right">
                  The idea of level up teams with the modern dynamics of mobile
                  games and help them enhance their skill sets aggressively.
                </p>
              </div>
              <div className="col-5">
                <img
                  className="why_hackathon_right_icon_bg"
                  alt=""
                  src="assets/images/why_hackathon_right_icon_bg.png"
                />
                <div className="parallax-image-inner">
                  <img
                    className="parallax-image why_hackathon_right_icon"
                    alt=""
                    src="assets/images/why_hackathon_right_icon.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>
      <div className="together_towards_tomorrow_border_bottom">
        <div className="marquee">
          <div className="marquee--inner">
            <span>
              <div>
                <img
                  alt=""
                  src="assets/images/together_towards_tomorrow_border_bottom.png"
                />
              </div>
            </span>
            <span>
              <div>
                <img
                  alt=""
                  src="assets/images/together_towards_tomorrow_border_bottom.png"
                />
              </div>
            </span>
          </div>
        </div>
      </div>

      <div className="hackathon_program">
        <div className="container">
          <div className="row">
            <div className="col-5">
              <h2>
                What is{" "}
                <img
                  alt=""
                  src="assets/images/hackathon_program_title_icon.png"
                />
                <br /> Hackathon Program
              </h2>
              <p className="animate-text-from-right">
                Our 24-day hackathon is a thrilling journey divided into two
                distinct phases:
              </p>
              <div className="phase_area">
                <h3>
                  <span className="fadein_bottom_23">Phase 1</span>Prototype
                  Phase
                </h3>
              </div>
              <div className="phase_area">
                <h3>
                  <span className="fadein_bottom_23">Phase 2</span>Market
                  Testing Phase
                </h3>
              </div>
            </div>
            <div className="col-7">
              <div className="phase_area_days fadein_bottom_23">
                <div className="phase_area_days_icon">
                  <img
                    className="days_1_to_12_icon_left"
                    alt=""
                    src="assets/images/days_1_to_12_icon_left.png"
                  />
                  <img
                    className="days_1_to_12_icon_right"
                    alt=""
                    src="assets/images/days_1_to_12_icon_right.png"
                  />
                </div>
                <h3>Prototype Phase ( Days 1-12 )</h3>
                <p>
                  Teams dive into a 12-day innovation challenge, creating three
                  games from scratch. The focus is on crafting engaging game
                  play and refining every aspect, unleashing creativity and
                  technical prowess to ensure a solid foundation for the next
                  phase.
                </p>
              </div>
              <div className="phase_area_days2 fadein_bottom_23">
                <h3>Market Testing Phase ( Days 13-24 )</h3>
                <p>
                  Entering market testing, teams launch global UA campaigns for
                  12 days. Leveraging prototype insights, they refine
                  strategies, aiming for broader reach. This dynamic phase
                  blends creativity, analytics, and strategy as teams position
                  games for global success.
                </p>
                <img
                  className="phase_area_days2_bg"
                  alt=""
                  src="assets/images/phase_area_days2_bg.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="together_towards_tomorrow_border_bottom">
        <img alt="" src="assets/images/hackathon_program_border_bottom.png" />
      </div>

      <div className="journey_of_game_district">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img alt="" src="assets/images/Journey_left_arrow.png" />{" "}
                <span>Journey</span>{" "}
                <img alt="" src="assets/images/Journey_right_arrow.png" />
              </h2>
              <p>
                The following cycle was used on this epic journey of Game
                District Hackathon 2023.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Journey_1">
                <img className="one" alt="" src="assets/images/one.png" />
                <div className="Journey_1_info">
                  <h3 className="animate-text-from-left">Ideation</h3>
                  <img
                    className="Journey-Icon-1 wow rollIn"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/Journey-Icon-1.png"
                  />
                </div>
              </div>
              <div className="Journey_arrow">
                <img alt="" src="assets/images/Journey_arrow.png" />
              </div>
              <div className="Journey_2">
                <img className="two" alt="" src="assets/images/two.png" />
                <div className="Journey_2_info">
                  <h3 className="animate-text-from-left">
                    Game
                    <br />
                    Creation
                  </h3>
                  <img
                    className="Journey-Icon-2 wow rollIn"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/Journey-Icon-2.png"
                  />
                </div>
              </div>
              <div className="Journey_arrow">
                <img alt="" src="assets/images/Journey_arrow.png" />
              </div>
              <div className="Journey_3">
                <img className="three" alt="" src="assets/images/three.png" />
                <div className="Journey_3_info">
                  <h3 className="animate-text-from-left">
                    Retention
                    <br />
                    Testing
                  </h3>
                  <img
                    className="Journey-Icon-3 wow rollIn"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/Journey-Icon-3.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="Journey_4">
                <img className="four" alt="" src="assets/images/four.png" />
                <div className="Journey_4_info">
                  <h3 className="animate-text-from-left">
                    User
                    <br />
                    Aquisition
                  </h3>
                  <img
                    className="Journey-Icon-4 wow rollIn"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/Journey-Icon-4.png"
                  />
                </div>
              </div>
              <div className="Journey_arrow2">
                <img alt="" src="assets/images/Journey_arrow.png" />
              </div>
              <div className="Journey_5">
                <img className="five" alt="" src="assets/images/five.png" />
                <div className="Journey_5_info">
                  <h3 className="animate-text-from-left">Sclae</h3>
                  <img
                    className="Journey-Icon-5 wow rollIn"
                    data-wow-delay="0.5s"
                    alt=""
                    src="assets/images/Journey-Icon-5.png"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="be_bold_make_history_icon">
                {" "}
                <img
                  className="icon_big"
                  alt=""
                  src="assets/images/be_bold_make_history_icon.png"
                />
                <img
                  className="icon_small wow pulse"
                  data-wow-delay="300ms"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                  alt=""
                  src="assets/images/be_bold_make_history_icon_small.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="together_towards_tomorrow_border_bottom">
        <img alt="" src="assets/images/journey_of_game_district_border.png" />
      </div>

      <div className="achievements_programs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                Achievements{" "}
                <img alt="" src="assets/images/the_battles_games.png" />
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-3">
              <div className="game_scaled_globally">
                <h3>
                  01 <img alt="" src="assets/images/game_scaled_globally.png" />
                </h3>
                <h4>Game Scaled Globally</h4>
              </div>
              <div className="game_scaled_globally2">
                <div className="eight_games">
                  <h3>8</h3>
                  <p>Games</p>
                </div>
                <img
                  className="eight_games_icon"
                  alt=""
                  src="assets/images/eight_games_icon.png"
                />
              </div>
              <div className="retention_marked">
                <h3>30%+</h3>
                <p>Retention Marked</p>
              </div>
            </div>
            <div className="col-5">
              <div className="million_game_downloads">
                <h3>10M</h3>
                <p>Game Downloads</p>
              </div>
            </div>
            <div className="col-4">
              <div className="prototypes">
                <h3>30</h3>
                <p>
                  Prototypes{" "}
                  <img alt="" src="assets/images/prototypes_icon.png" />
                </p>
              </div>
              <div className="prototypes_img">
                <img alt="" src="assets/images/phase_area_days2_bg.png" />
              </div>

              <div className="gap_img">
                <img alt="" src="assets/images/gap_img.png" />
              </div>

              <div className="hit_games">
                <h3>03</h3>
                <p>Hit Games</p>
                <img
                  className="hit_games_icons"
                  alt=""
                  src="assets/images/hit_games_icons.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="successful_games">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img
                  alt=""
                  src="assets/images/successful_games_left_arrow.png"
                />{" "}
                <span>Success</span>{" "}
                <img
                  alt=""
                  src="assets/images/successful_games_right_arrow.png"
                />
              </h2>
              <p>Some of the successful games made in hackathon.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <div className="successful_games_info margin_top_games fadein_bottom_23">
                <img
                  className="game_icon"
                  alt=""
                  src="assets/images/shape_transform_shifting_car.png"
                />
                <div className="game_info">
                  <h5>Shape Transform Shifting Car</h5>
                  <p>
                    {" "}
                    <img alt="" src="assets/images/download_icon.png" />
                    500k+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="successful_games_info fadein_bottom_23">
                <img
                  className="game_icon"
                  alt=""
                  src="assets/images/running_ball_numbers_game.png"
                />
                <div className="game_info">
                  <h5>Running Ball Numbers 2048 Game</h5>
                  <p>
                    {" "}
                    <img alt="" src="assets/images/download_icon.png" />
                    100k+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="successful_games_info margin_top_games fadein_bottom_23">
                <img
                  className="game_icon"
                  alt=""
                  src="assets/images/stickman_thief_brain_puzzle.png"
                />
                <div className="game_info">
                  <h5>Stickman Thief Brain Puzzle</h5>
                  <p>
                    {" "}
                    <img alt="" src="assets/images/download_icon.png" />
                    500k+
                  </p>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="successful_games_info fadein_bottom_23">
                <img
                  className="game_icon"
                  alt=""
                  src="assets/images/mom_running_games_mom_games.png"
                />
                <div className="game_info">
                  <h5>Mom Running Games Mom Games</h5>
                  <p>
                    {" "}
                    <img alt="" src="assets/images/download_icon.png" />
                    100k+
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="gallery_game_district">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="gallery_gd_area">
                <div className="gallery_gd_row">
                  <img
                    className="gallery_1"
                    alt=""
                    src="assets/images/gallery_1.png"
                  />
                  <img
                    className="gallery_2"
                    alt=""
                    src="assets/images/gallery_2.png"
                  />
                  <div className="clr"></div>
                  <img
                    className="gallery_3"
                    alt=""
                    src="assets/images/gallery_3.png"
                  />
                  <img
                    className="gallery_4"
                    alt=""
                    src="assets/images/gallery_4.png"
                  />
                </div>
                <div className="gallery_gd_row2">
                  <img alt="" src="assets/images/gallery_5.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="gallery_gd_area">
                <div className="gallery_gd_row2">
                  <img alt="" src="assets/images/gallery_6.png" />
                </div>
                <div className="gallery_gd_row">
                  <img
                    className="gallery_1"
                    alt=""
                    src="assets/images/gallery_7.png"
                  />
                  <img
                    className="gallery_2"
                    alt=""
                    src="assets/images/gallery_8.png"
                  />
                  <div className="clr"></div>
                  <img
                    className="gallery_3"
                    alt=""
                    src="assets/images/gallery_9.png"
                  />
                  <img
                    className="gallery_4"
                    alt=""
                    src="assets/images/gallery_10.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="contact_us_programs">
        <div className="contact_us_programs_inner">
          <div className="container">
            <div className="row">
              <div className="col-8">
                <div className="hardworking wow backInDown">
                  <p>
                    We’re hardworking
                    <br /> yet fun-loving
                  </p>
                </div>

                <h1 className="">
                  {" "}
                  <span id="flipper" className="flip">
                    <span className="step step0 set">We love</span>
                    <span className="step step1">You love</span>
                    <span className="step step2">We love</span>
                    <span className="step step3">You love</span>
                    <span className="step step4">We love</span>
                    <span className="step step5">You love</span>
                  </span>{" "}
                  <span className="righttext wow bounceInRight">
                    We love
                    <br />
                    what
                    <br />
                    we do
                    <br />
                    what
                    <br />
                    we love
                  </span>
                </h1>
                <h2>
                  <img alt="" src="assets/images/arrow_awesome.png" />
                  awesome
                </h2>
                <h3>games</h3>
              </div>
              <div className="col-4">
                <div className="bolt fadein_bottom_12">
                  <img alt="" src="assets/images/bolt.png" />
                </div>
              </div>
            </div>

            <div className="just_make_games wow">
              <div className="right_text">
                <span className="text2">how cool are we</span>
                <span className="text">Check out</span>
              </div>
              <div className="social_icons_footer bounceInRight">
                <a href="#">
                  <img alt="" src="assets/images/icon_instagram_footer.png" />
                </a>
                <a href="#">
                  <img alt="" src="assets/images/icon_inn_footer.png" />
                </a>
              </div>
              <div className="just_play_text bounceInRight">
                <a href="#" className="circle-btn welcome-img-btn">
                  <span className="circle-btn__text">
                    JUST PLAY GAMES. JUST MAKE GAMES{" "}
                  </span>
                </a>
                <img alt="" src="assets/images/just_play_arrow.png" />
              </div>
            </div>
          </div>
          <div className="contactus_programs_info">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Contact us</h1>
                </div>
              </div>
              <div className="row">
                <ContactUs colclassName="col-6" />
                {/* <div className="col-6">
                  <div className="contactinfo">
                    <p>
                      Team Game District is just a click away from you. Connect
                      with us to get solutions to your business growth, market
                      existence, and sustainability.
                    </p>
                    <div className="sign_up">
                      <h4>Stay up to date on global innovations.</h4>
                      <input
                        className="email"
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                      />
                      <input
                        className="submit"
                        type="submit"
                        value="Sign Up"
                        name="submit"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="submit_project">
                    <input
                      className="text"
                      type="text"
                      name="name"
                      placeholder="Name"
                    />
                    <input
                      className="text"
                      type="email"
                      placeholder="Email Address"
                      name="email"
                    />
                    <div className="select">
                      <select>
                        <option>Category</option>
                        <option>Category1</option>
                        <option>Category2</option>
                        <option>Category3</option>
                        <option>Category4</option>
                        <option>Category5</option>
                        <option>Category6</option>
                      </select>
                    </div>
                    <div className="select">
                      <select>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                      </select>
                    </div>
                    <textarea
                      className="textarea"
                      placeholder="Project Details"
                    ></textarea>
                    <input
                      className="file"
                      type="file"
                      name="file"
                      placeholder="Browse"
                    />
                    <input
                      className="submit"
                      type="submit"
                      value="Submit Project"
                      name="submit"
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="clr"></div>
          </div>

          <div className="clr"></div>
        </div>
      </div>

      <div className="footer">
        <div className="footer_area_programs">
          <div className="container">
            <div className="row">
              <div className="col-6 animate-text-from-bottom">
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
              <div className="col-6 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li className="active">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/About">About</a>
                  </li>
                  <li>
                    <a href="/Games">Games</a>
                  </li>
                  <li>
                    <a href="/gdhub">publishing</a>
                  </li>
                  <li>
                    <a href="/tools">Growth Sandbox</a>
                  </li>
                  <li>
                    <a href="/ip">IP</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>
    </>
  );
};

export default Hackathon;
