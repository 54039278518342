import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
export default function Header(props) {
  const _openNav = () => {
    document.getElementById("content_menu").style.width = "250px";
  };

  const _closeNav = () => {
    document.getElementById("content_menu").style.width = "0";
  };

  return (
    <div className="">
      <div className="header">
        <div className="logo">
          <a title="GameDistrict" href="/">
            <img
              className="logo_black"
              alt="game-district"
              src={"assets/images/logo.png"}
            />
            <img
              className="logo_white"
              alt="game-district"
              src={"assets/images/logo_w.png"}
            />
          </a>
        </div>
        <div className="main_menu">
          <ul>
            <li
              className={
                window.location.pathname && window.location.pathname == "/"
                  ? "active"
                  : ""
              }
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={
                window.location.pathname && window.location.pathname == "/About"
                  ? "active"
                  : ""
              }
            >
              <Link to="/about">About</Link>
            </li>
            <li
              className={
                window.location.pathname && window.location.pathname == "/Games"
                  ? "active"
                  : ""
              }
            >
              <Link to="/games">Games</Link>
            </li>
            <li
              className={
                window.location.pathname && window.location.pathname == "/gdhub"
                  ? "active"
                  : ""
              }
            >
              <Link to="/gdhub">GD HUB</Link>
            </li>
            <li>
              <Link to="#">Growth Sandbox</Link>
              <ul>
                <li>
                  <h3>Tools</h3>
                </li>
                <li>
                  <Link to="/tools">Applifier</Link>
                </li>
                <li>
                  <Link to="/tools">GDI</Link>
                </li>
                <li>
                  <h3>Programs</h3>
                </li>
                <li
                  className={
                    window.location.pathname &&
                    window.location.pathname == "/pixls"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/pixls">Pixls</Link>
                </li>
                <li
                  className={
                    window.location.pathname &&
                    window.location.pathname == "/hackathon"
                      ? "active"
                      : ""
                  }
                >
                  <Link to="/hackathon">Hackathon</Link>
                </li>
              </ul>
            </li>
            <li
              className={
                window.location.pathname && window.location.pathname == "/ip"
                  ? "active"
                  : ""
              }
            >
              <a href="/ip">IP</a>
            </li>
            <li
              className={
                window.location.pathname && window.location.pathname == "/blogs"
                  ? "active"
                  : ""
              }
            >
              <a href="/blogs">Blogs</a>
            </li>
            <li
              className={
                window.location.pathname &&
                window.location.pathname == "/contact"
                  ? "active"
                  : ""
              }
            >
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
        <div className="mobile_menu">
          <span onClick={() => _openNav()}>&#9776;</span>
          <div id="content_menu" className="mobilemenu">
            <span className="closebtn" onClick={() => _closeNav()}>
              &times;
            </span>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/games">Games</Link>
            <Link to="/gdhub">publishing</Link>
            <Link to="/tools">Growth Sandbox</Link>
            <Link to="/ip">IP</Link>
            <Link to="/blogs">Blogs</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </div>

        <div className="clr"></div>
      </div>
    </div>
  );
}
