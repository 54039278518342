import React, { useEffect, useRef, useState } from "react";

import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
const Tools = () => {
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    $(".item").click(function () {
      $(this).addClass("active").siblings(".item").removeClass("active");
      $(this)
        .next()
        .show()
        .animate({ width: "36%" })
        .siblings(".info")
        .animate({ width: 0 }, function () {
          $(this).hide();
        });
    });
    setTimeout(() => {
      setLoader(false);
    }, 2800);
  }, []);
  const openCity = (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  return (
    <>
      <Helmet>
        <title>Game District IP - Game District</title>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        {/* <script src="assets/slider/jquery.min.js"></script> */}
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        <link rel="stylesheet" href="assets/slider/owl.carousel.min.css" />
        <link rel="stylesheet" href="assets/slider/owl.theme.default.min.css" />

        <script src="assets/slider/owl.carousel.js"></script>
        <script src="assets/slider/jquery.mousewheel.min.js"></script>
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>

        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        <script src="https://jsfiddle.net/js/stringify.js?ceaeb44bfd207968b44d260d6b94e6686e85ba92"></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/modernizr/2.8.3/modernizr.min.js"
          type="text/javascript"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/gsap/latest/TweenMax.min.js"
          type="text/javascript"
        ></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>

        <script>
          {`
            setTimeout(() => {
              var $brandanimation = $(".brandanimation");
		var $list = $brandanimation.find("ul.list");
		var $clonedList = $list.clone();
		var listWidth = 10;

		$list.find("li").each(function (i) {
			listWidth += $(this, i).outerWidth(true);
		});

		var endPos = $brandanimation.width() - listWidth;

		$list.add($clonedList).css({
			"width": listWidth + "px"
		});

		$clonedList.addClass("cloned").appendTo($brandanimation);

		//TimelineMax
		var infinite2 = new TimelineMax({ repeat: -1, paused: true });
		var time = 40;

		infinite2
			.fromTo($list, time, { rotation: 0.01, x: 0 }, { force3D: true, x: listWidth, ease: Linear.easeNone }, 0)
			.fromTo($clonedList, time, { rotation: 0.01, x: -listWidth }, { force3D: true, x: 0, ease: Linear.easeNone }, 0)
			.set($list, { force3D: true, rotation: 0.01, x: -listWidth })
			.to($clonedList, time, { force3D: true, rotation: 0.01, x: listWidth, ease: Linear.easeNone }, time)
			.to($list, time, { force3D: true, rotation: 0.01, x: 0, ease: Linear.easeNone }, time)
			.progress(1).progress(0)
			.play();



		var $brandanimationcolor = $(".brandanimationcolor");
		var $list = $brandanimationcolor.find("ul.list");
		var $clonedList = $list.clone();
		var listWidth = 10;

		$list.find("li").each(function (i) {
			listWidth += $(this, i).outerWidth(true);
		});

		var endPos = $brandanimationcolor.width() - listWidth;

		$list.add($clonedList).css({
			"width": listWidth + "px"
		});

		$clonedList.addClass("cloned").appendTo($brandanimationcolor);

		//TimelineMax
		var infinite3 = new TimelineMax({ repeat: -1, paused: true });
		var time = 40;

		infinite3
			.fromTo($list, time, { rotation: 0.01, x: 0 }, { force3D: true, x: -listWidth, ease: Linear.easeNone }, 0)
			.fromTo($clonedList, time, { rotation: 0.01, x: listWidth }, { force3D: true, x: 0, ease: Linear.easeNone }, 0)
			.set($list, { force3D: true, rotation: 0.01, x: listWidth })
			.to($clonedList, time, { force3D: true, rotation: 0.01, x: -listWidth, ease: Linear.easeNone }, time)
			.to($list, time, { force3D: true, rotation: 0.01, x: 0, ease: Linear.easeNone }, time)
			.progress(1).progress(0)
			.play();



            const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});

  const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'


            },3000)
 
      `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
        `}</style>
      </Helmet>
      {loader ? (
        <div className="loaderLayout">
          <img alt="" src="assets/images/logo.png" className="loaderLogo" />
        </div>
      ) : null}
      <div className="top_home_area">
        <Header />
        <div className="banner_tool">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                  <img
                    className="tool_growing"
                    alt=""
                    src="assets/images/tool_growing.png"
                  />
                  Stop flyin
                  <span className="star">
                    <img alt="" src="assets/images/tool_star.png" />
                  </span>
                  g blind
                </h2>
                <h5 className="wow fadeInUp" data-wow-delay="0.5s">
                  Supercharge your game’s growth
                  <br /> and evolve beyond limits
                </h5>
                <div className="gid_applifier_links">
                  <a href="#">
                    <img alt="GDI" src="assets/images/icon_gdi.png" />{" "}
                  </a>
                  <a href="#">
                    <img
                      alt="Applifier"
                      src="assets/images/icon_applifier.png"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tools_banner">
                  <img alt="" src="assets/images/banner-tools.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="brandanimation">
          <ul className="list">
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star.png" />
              Growth hacks
            </li>
          </ul>
        </div>

        <div className="brandanimationcolor">
          <ul className="list">
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              Growth hacks
            </li>
            <li className="listitem">
              <img alt="" src="assets/images/brand_star_color.png" />
              secret insignts
            </li>
          </ul>
        </div>

        <div className="clr"></div>

        <div className="insights_impact">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h2>
                  Insights to
                  <br />
                  Impact
                  <img alt="" src="assets/images/icon_insights_impact.png" />
                </h2>
              </div>
              <div className="col-6">
                <h3>Tools to fuel your growth</h3>
                <p className="animate-text-from-right">
                  In the fast-paced world of mobile gaming, data is your most
                  valuable asset. Here at Game District, we empower you to
                  transform that data into actionable strategies for explosive
                  growth. Our suite of powerful tools helps you unveil hidden
                  insights, optimize every aspect of your game, and make
                  data-driven decisions that propel you to the top.
                </p>
              </div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
      </div>

      <div className="applifier">
        <div className="container">
          <div className="row">
            <div id="applifier" className="col-12">
              <h2>
                Take the pain out of your <br />
                company procedures
              </h2>
              <p className="animate-text-from-right">
                We understand the complexities of managing a successful mobile
                game. That's why we offer a suite of powerful tools designed to
                streamline workflows, unlock valuable insights, and empower
                data-driven decisions.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <img
                className="applifier_left_img"
                alt=""
                src="assets/images/applifier_left_img.png"
              />
            </div>
            <div className="col-6">
              <div className="applifier_info">
                <img alt="" src="assets/images/applifier_logo.png" />
                <h3>Effortlessly optimize, amplify your reach.</h3>
                <p className="animate-text-from-right">
                  Applifier's user-friendly interface lets you easily adjust
                  campaigns and experiment with different strategies to maximize
                  user acquisition and engagement.
                </p>
                <div className="applifier_links">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-6 fadein_bottom_23">
              <div className="bidding_system ">
                <img alt="" src="assets/images/bidding_system.png" />
                <h3>Bidding System</h3>
                <p>
                  Advanced analytics unlock the secrets to player engagement. We
                  analyze behavior, pinpoint trends, and optimize for
                  growth.  See beyond the numbers, understand your players.
                </p>
              </div>
            </div>
            <div className="col-6 fadein_bottom_23">
              <div className="advanced_analytics ">
                <img alt="" src="assets/images/advanced_analytics.png" />
                <h3>Advanced Analytics</h3>
                <p>
                  Advanced analytics unlock the secrets to player engagement. We
                  analyze behavior, pinpoint trends, and optimize for
                  growth.  See beyond the numbers, understand your players.
                </p>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-3 fadein_bottom_23">
              <div className="applifier_system_info ">
                <div className="icon_system_info">
                  <img alt="" src="assets/images/icon_effortless_cross.png" />
                </div>
                <h3>Effortless Cross Promotions</h3>
                <p>
                  Effortlessly promote your existing games to users within your
                  network, maximizing engagement and boosting overall user base
                  growth.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="applifier_system_info ">
                <div className="icon_system_info">
                  <img
                    alt=""
                    src="assets/images/icon_laser_focused_marketing.png"
                  />
                </div>
                <h3>Laser focused Marketing</h3>
                <p>
                  Applifier helps you target specific demographics and countries
                  with tailored campaigns, maximizing user acquisition for the
                  most relevant audiences.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="applifier_system_info ">
                <div className="icon_system_info">
                  <img
                    alt=""
                    src="assets/images/icon_actionable_insights.png"
                  />
                </div>
                <h3>Actionable Insights</h3>
                <p>
                  Gain valuable insights into user behavior and campaign
                  performance, allowing you to refine your marketing strategies
                  and maximize ROI.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="applifier_system_info ">
                <div className="icon_system_info">
                  <img alt="" src="assets/images/icon_ab_testing_power.png" />
                </div>
                <h3>A/B Testing Power</h3>
                <p>
                  Run A/B tests to experiment with different creatives and
                  messaging, allowing you to identify the most effective
                  approach for reaching and engaging your target audience.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="go_beyond">
                <h2>Go Beyond the Surface</h2>
                <h3>Dive Deep with Advanced Analytics.</h3>
                <p className="animate-text-from-left">
                  Our tools make it easier than ever to achieve peak performance
                  for your game, freeing you to focus on what matters most:
                  creating a captivating experience for your players.
                </p>
                <div className="get_started_link">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-12">
              <div className="we_help">
                <div className="col-4">
                  <h3>We Help You Achieve More Efficiently!</h3>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">76</span>%
                  </h4>
                  <p>User Retention</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">98</span>%
                  </h4>
                  <p>Client Satisfaction</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">63</span>%
                  </h4>
                  <p>Improved CTR</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">44</span>%
                  </h4>
                  <p>Reduced CPI</p>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="gdi_area">
        <div className="container">
          <div className="row">
            <div id="gdi" className="col-12">
              <h2>
                From Casual Clicks
                <br />
                to Calculated Strategies
              </h2>
              <h4>Optimize, Evolve, Dominate</h4>
              <p>
                Imagine a powerful tool that acts as your game's personal
                detective, uncovering hidden trends, dissecting player behavior,
                and revealing the secrets to maximizing engagement and revenue.
              </p>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-5">
              <div className="gdi_info">
                <img alt="" src="assets/images/gdi_logo.png" />
                <h3>Unleash the Power of Deep Analytics</h3>
                <p className="animate-text-from-left">
                  Applifier's user-friendly interface lets you easily adjust
                  campaigns and experiment with different strategies to maximize
                  user acquisition and engagement.
                </p>
                <div className="gdi_links">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
            <div className="col-7">
              <img
                className="gdi_left_img"
                alt=""
                src="assets/images/gdi_img-right.png"
              />
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-6 fadein_bottom_23">
              <div className="monetization_mastermind ">
                <img alt="" src="assets/images/monetization_mastermind.png" />
                <h3>Monetization Mastermind</h3>
                <p>
                  Gain a deep understanding of player spending habits, allowing
                  you to optimize revenue streams and unlock new growth
                  opportunities.
                </p>
              </div>
            </div>
            <div className="col-6 fadein_bottom_23">
              <div className="benchmark ">
                <img alt="" src="assets/images/benchmark.png" />
                <h3>Benchmark Your Success</h3>
                <p>
                  Compare key performance indicators (KPIs) against industry
                  benchmarks to understand your position and identify areas for
                  improvement.
                </p>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-3 fadein_bottom_23">
              <div className="gdi_system_info ">
                <div className="icon_system_info">
                  <img alt="" src="assets/images/icon_effortless_cross.png" />
                </div>
                <h3>Customizable Dashboards</h3>
                <p>
                  Tailor your analytics experience with customizable dashboards
                  that focus on the metrics most relevant to your game's
                  success.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="gdi_system_info ">
                <div className="icon_system_info">
                  <img
                    alt=""
                    src="assets/images/icon_laser_focused_marketing.png"
                  />
                </div>
                <h3>A/B Testing Made Easy</h3>
                <p>
                  Run A/B tests to experiment with different game features and
                  monetization strategies, identifying the most effective
                  approach for maximum impact.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="gdi_system_info ">
                <div className="icon_system_info">
                  <img
                    alt=""
                    src="assets/images/icon_actionable_insights.png"
                  />
                </div>
                <h3>Future-Proof Your Game</h3>
                <p>
                  Identify areas for improvement and prioritize development
                  efforts based on real user behavior, ensuring your game
                  continues to evolve and captivate audiences.
                </p>
              </div>
            </div>
            <div className="col-3 fadein_bottom_23">
              <div className="gdi_system_info ">
                <div className="icon_system_info">
                  <img alt="" src="assets/images/icon_ab_testing_power.png" />
                </div>
                <h3>Uncover Hidden Trends</h3>
                <p>
                  GDI goes beyond basic metrics, analyzing user behavior
                  patterns to pinpoint what truly keeps players engaged.
                </p>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-12">
              <div className="go_beyond">
                <h2>
                  Effortlessly Transform Insights
                  <br />
                  into Actionable Strategies
                </h2>
                <p className="animate-text-from-left">
                  This isn't just about numbers - it's about unlocking the full
                  potential within your game. It's about transforming data into
                  a roadmap for crafting the most engaging experience possible.
                  Let's turn insights into action and propel your game to the
                  top.
                </p>
                <div className="get_started_link">
                  <a href="#">Get Started</a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="row">
            <div className="col-12">
              <div className="we_help_gdi">
                <div className="col-4">
                  <h3>Engage, Retain, Grow & Monetize Your Masterpiece</h3>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">76</span>%
                  </h4>
                  <p>User Retention</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">98</span>%
                  </h4>
                  <p>Positive Feedback</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">63</span>%
                  </h4>
                  <p>Improved CTR</p>
                </div>
                <div className="col-2">
                  <h4>
                    <span className="counter">44</span>%
                  </h4>
                  <p>Reduced CPI</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
      <Footer />
      {/* <div className="contact_us">
        <div className="container">
          <div className="row">
            <ContactUs />
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gamedistrict.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
};

export default Tools;
