import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUs";
export default function Footer() {
  return (
    <>
      <div className="contact_us hide">
        <div className="container">
          <div className="row">
            <ContactUs />
            {/* <div className="col-4">
              <h1>Contact us</h1>
              <p>
                Team Game District is just a click away from you. Connect with
                us to get solutions to your business growth, market existence,
                and sustainability.
              </p>
              <div className="sign_up">
                <h4>Stay up to date on global innovations.</h4>
                <input
                  className="email"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                />
                <input
                  className="submit"
                  type="submit"
                  value="Sign Up"
                  name="submit"
                />
              </div>
            </div>
            <div className="col-4">
              <div className="just_play">
                <div className="just_play_text">
                  <a href="#" className="circle-btn welcome-img-btn">
                    <span className="circle-btn__text">
                      JUST PLAY GAMES. JUST MAKE GAMES
                    </span>
                  </a>
                  <img alt="" src="assets/images/just_play_arrow.png" />
                </div>
                <div className="submit_project">
                  <input
                    className="text"
                    type="text"
                    name="name"
                    placeholder="Name"
                  />
                  <input
                    className="text"
                    type="email"
                    placeholder="Email Address"
                    name="email"
                  />
                  <div className="select">
                    <select>
                      <option>Category</option>
                      <option>Category1</option>
                      <option>Category2</option>
                      <option>Category3</option>
                      <option>Category4</option>
                      <option>Category5</option>
                      <option>Category6</option>
                    </select>
                  </div>
                  <div className="select">
                    <select>
                      <option>Approx Budget</option>
                      <option>Approx Budget</option>
                      <option>Approx Budget</option>
                      <option>Approx Budget</option>
                      <option>Approx Budget</option>
                    </select>
                  </div>
                  <textarea
                    className="textarea"
                    placeholder="Project Details"
                  ></textarea>
                  <input
                    className="file"
                    type="file"
                    name="file"
                    placeholder="Browse"
                  />
                  <input
                    className="submit"
                    type="submit"
                    value="Submit Project"
                    name="submit"
                  />
                </div>
              </div>
            </div> */}
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer hide">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/games">Games</a>
                  </li>
                  <li>
                    <a href="/gdhub">GD Hub</a>
                  </li>
                  <li>
                    <a href="/ip">IP</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="/pixls">Pixls</a>
                  </li>
                  <li>
                    <a href="/hackathon">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="/tools">GDI</a>
                  </li>
                  <li>
                    <a href="/tools">Applifier</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gamedistrict.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>
    </>
  );
}
