import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Games from "./components/Games";
import GDHub from "./components/Gdhub";
import Header from "./components/Header";
import Home from "./components/Home";
import {
  BrowserRouter,
  Route,
  Router,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import Ip from "./components/Ip";
import Blogs from "./components/blogs";
import Tools from "./components/Tools";
import Pixels from "./components/Pixels";
import Hackathon from "./components/Hackathon";
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/games",
      element: <Games />,
    },
    {
      path: "/gdhub",
      element: <GDHub />,
    },
    {
      path: "/tools",
      element: <Tools />,
    },
    {
      path: "/pixls",
      element: <Pixels />,
    },
    {
      path: "/hackathon",
      element: <Hackathon />,
    },
    {
      path: "/ip",
      element: <Ip />,
    },
    {
      path: "/blogs",
      element: <Blogs />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
  ]);

  return (
    // <BrowserRouter>
    //   <Routes>
    //     <Route path="/" Component={Home} />
    //     <Route path="/about" component={About} />
    //   </Routes>
    // </BrowserRouter>
    // <>
    //   {/* <Header /> */}
    <RouterProvider router={router} />
    // </>
    // <Router>
    //   {/* <Routes> */}
    //     {/* <Header /> */}
    //     {/* <Switch> */}
    //     <Route
    //       exact
    //       path="/"
    //       render={(props) => (
    //         <>
    //           <Home />
    //         </>
    //       )}
    //     />
    //     {/* <Route path="/about" component={About} />
    //     <Route path="/contact" component={Contact} />
    //     <Route path="/games" component={Games} />
    //     <Route path="/gdhub" component={GDHub} />
    //     <Route path="/ip" component={ip} />
    //     <Route path="/hackathon" component={Hackathon} />
    //     <Route path="/pixls" component={Pixls} />
    //     <Route path="/tools" component={Tools} /> */}
    //     {/* <Redirect to="/" /> */}
    //     {/* </Switch> */}
    //     {/* <Footer /> */}
    //   {/* </Routes> */}
    // </Router>
  );
}

export default App;
