import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

// import styles bundle
import "swiper/css/bundle";

export default function About() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    $(".item").click(function () {
      $(this).addClass("active").siblings(".item").removeClass("active");
      $(this)
        .next()
        .show()
        .animate({ width: "36%" })
        .siblings(".info")
        .animate({ width: 0 }, function () {
          $(this).hide();
        });
    });
    setTimeout(() => {
      //   setLoader(false);
    }, 2800);

    const swiper = new Swiper(".swiper-container", {
      loop: true,
      slidesPerView: 3,
      effect: "coverflow",
      spaceBetween: 30,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        760: {
          slidesPerView: 1,
        },
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
        },
      },
    });

    const swiper2 = new Swiper(".swiper-container2", {
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      effect: "spring",
      mousewheel: true,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        760: {
          slidesPerView: 1,
        },
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
        },
      },
    });

    const swiper3 = new Swiper(".swiper-container3", {
      loop: true,
      slidesPerView: 3,
      // centeredSlides: true,
      effect: "spring",
      mousewheel: true,
      spaceBetween: 30,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      // nextButton: ".swiper-button-next",
      // prevButton: ".swiper-button-prev",
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        760: {
          slidesPerView: 1,
        },
        // when window width is >= 320px
        768: {
          slidesPerView: 2,
        },
        // when window width is >= 480px
        1024: {
          slidesPerView: 3,
        },
      },
    });
  }, []);
  const openCity = (evt, cityName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";
  };
  return (
    <>
      <Helmet>
        <title>About - Game District</title>
        {/* <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script> */}
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js"></script> */}
        <script src="assets/slider/jquery.min.js"></script>
        <script src="assets/js/wow.min.js"></script>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        {/* <link
          type="text/css"
          href="assets/css/swiper.min.css"
          rel="stylesheet"
        />
        <script type="text/javascript" src="assets/js/swiper.min.js"></script> */}
        {/* <link rel="stylesheet" href="assets/slider/owl.carousel.min.css" /> */}
        {/* <script src="assets/slider/owl.carousel.js"></script> */}
        {/* <script src="assets/slider/jquery.mousewheel.min.js"></script> */}
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
          function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {

  new WOW().init();
var TxtType = function (el, toRotate, period) {
  this.toRotate = toRotate;
  this.el = el;
  this.loopNum = 0;
  this.period = parseInt(period, 10) || 2000;
  this.txt = "";
  this.tick();
  this.isDeleting = false;
};

TxtType.prototype.tick = function () {
  var i = this.loopNum % this.toRotate.length;
  var fullTxt = this.toRotate[i];

  if (this.isDeleting) {
    this.txt = fullTxt.substring(0, this.txt.length - 1);
  } else {
    this.txt = fullTxt.substring(0, this.txt.length + 1);
  }

  this.el.innerHTML = '<span className="wrap">' + this.txt + "</span>";

  var that = this;
  var delta = 200 - Math.random() * 100;

  if (this.isDeleting) {
    delta /= 2;
  }

  if (!this.isDeleting && this.txt === fullTxt) {
    delta = this.period;
    this.isDeleting = true;
  } else if (this.isDeleting && this.txt === "") {
    this.isDeleting = false;
    this.loopNum++;
    delta = 500;
  }

  setTimeout(function () {
    that.tick();
  }, delta);
};


  var elements = document.getElementsByClassName("typewrite");
  for (var i = 0; i < elements.length; i++) {
    var toRotate = elements[i].getAttribute("data-type");
    var period = elements[i].getAttribute("data-period");
    if (toRotate) {
      new TxtType(elements[i], JSON.parse(toRotate), period);
    }
  }
  // INJECT CSS
  var css = document.createElement("style");
  css.type = "text/css";
  css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #000}";
  document.body.appendChild(css);
// var owl = $(".blog-slider");
// owl.owlCarousel({
//   loop: true,
//   nav: false,
//   dots: false,
//   margin: 25,
//   items: 4,
//   responsive: {
//     0: {
//       items: 1,
//     },
//     600: {
//       items: 2,
//     },
//     960: {
//       items: 2,
//     },
//     1200: {
//       items: 3,
//     },
//   },
// });
// owl.on("mousewheel", ".owl-stage", function (e) {
//   if (e.deltaY > 0) {
//     owl.trigger("prev.owl");
//   } else {
//     owl.trigger("next.owl");
//   }
//   e.preventDefault();
// });
// var owl2 = $('.nonloop');
// owl2.owlCarousel({
//       loop: true,
//                 nav: false,
// 				   dots: false,
//                 margin: 25,
// 				    items:1,
//                 responsive: {
//                   0: {
//                     items: 1
//                   },
//                   600: {
//                     items: 1
//                   },
//                   960: {
//                     items: 1
//                   },
//                   1200: {
//                     items: 1
//                   }
//                 }
// });
// owl2.on("mousewheel", ".owl-stage", function (e) {
//   if (e.deltaY > 0) {
//     owl2.trigger("prev.owl");
//   } else {
//     owl2.trigger("next.owl");
//   }
//   e.preventDefault();
// });

// var mySwiper = new Swiper ('.swiper-container', {

//   loop: true,
//   slidesPerView : 3,
//   centeredSlides : true,
//   effect : 'coverflow',
//   coverflow: {
//             rotate: 0,
//             stretch: 54,
//             depth: 300,
//             modifier: 1,
//         },

//   nextButton: '.swiper-button-next',
//   prevButton: '.swiper-button-prev',
//   }) 

  fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});

const imageParallax = document.querySelectorAll(".parallax-image");

		if (imageParallax.length > 0) {
			imageParallax.forEach(function (element) {
				const wrapper = document.createElement("div");
				wrapper.className = "parallax-image-wrap";

				const innerWrapper = document.createElement("div");
				innerWrapper.className = "parallax-image-inner";

				element.parentNode.insertBefore(wrapper, element);
				wrapper.appendChild(innerWrapper);
				innerWrapper.appendChild(element);

				wrapper.style.overflow = "hidden";

				const animImageParallax = element;
				const imgParallaxWrapper = wrapper;
				const innerWrap = innerWrapper;

				const tlImageParallax = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						onEnter: animImgParallaxRefresh,
					},
				});

				tlImageParallax.to(animImageParallax, {
					yPercent: 35,
					ease: "none",
				});

				// Function to refresh the scroll trigger
				function animImgParallaxRefresh() {
					tlImageParallax.scrollTrigger.refresh();
				}

				// Animation setup for zoom-in effect
				const tlZoomIn = gsap.timeline({
					scrollTrigger: {
						trigger: imgParallaxWrapper,
						start: "top 100%",
					},
				});

				tlZoomIn.from(innerWrap, {
					duration: 1.5,
					opacity: 0,
					scale: 1.2,
					ease: "power2.out",
					clearProps: "all",
				});
			});
		}

    const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'

          },3000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
          .owl-item .item .box {
            transform: scale(0.9) !important;
            transition: transform 1s !important;
          }
          .owl-item.active .item .box {
            transform: scale(1) !important;
          }
        `}</style>
      </Helmet>
      <div className="top_home_area">
        <Header page="about" />

        <div className="banner_about">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                  Incredible
                  <span
                    className="star  wow pulse"
                    data-wow-delay="300ms"
                    data-wow-iteration="infinite"
                    data-wow-duration="2s"
                  >
                    <img alt="" src="assets/images/star_about.png" />
                  </span>{" "}
                  people
                  <img
                    className="growing"
                    alt=""
                    src="assets/images/icon_about_right.png"
                  />
                </h2>
                <h1 className="wow fadeInUp" data-wow-delay="0.5s">
                  <span className="star">
                    <img alt="" src="assets/images/icon_about_left.png" />
                  </span>
                  working together to make{" "}
                </h1>
                <h1 className="wow fadeInUp" data-wow-delay="0.5s">
                  awesome
                  <span className="icon_about_title">
                    <img alt="" src="assets/images/icon_about_title.png" />
                  </span>{" "}
                  mobile games
                </h1>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="about_banner">
                  <img alt="" src="assets/images/about_banner.png" />
                </div>
                <div className="clr"></div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="about_us">
        <div className="about_us_col">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>
                  <img
                    className="about_us_title_icon_left"
                    alt=""
                    src="assets/images/about_us_title_icon_left.png"
                  />
                  <span>About Us</span>
                  <img
                    className="about_us_title_icon_right"
                    alt=""
                    src="assets/images/about_us_title_icon_right.png"
                  />{" "}
                  <img
                    className="about_us_icon_right"
                    src="assets/images/about_us_icon-right.png"
                  />{" "}
                </h2>
                <p className="animate-text-from-right">
                  At Game District, we're more than just a mobile game developer
                  - we're a global force redefining mobile entertainment. From
                  our humble beginnings, we've ignited a whirlwind of
                  creativity, crafting captivating games across multiple genres
                  that resonate with players worldwide.
                </p>
                <h3>Company Philosphy</h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="company_philosphy">
                  {/* <div className="nonloop owl-carousel owl-theme"> */}
                  <div className="swiper-container2">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_trust_empowerment.png"
                            />
                          </div>
                          <h4>Trust & Empowerment</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_unleashing_potential.png"
                            />
                          </div>
                          <h4>Unleashing Potential</h4>
                          <p>
                            We’re in it together. Our shared knowledge enables
                            synergies through collaborations across the growing
                            ecosystem.
                          </p>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_embracing_eachother.png"
                            />
                          </div>
                          <h4>Embracing eachother</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_unleashing_potential.png"
                            />
                          </div>
                          <h4>Unleashing Potential</h4>
                          <p>
                            We’re in it together. Our shared knowledge enables
                            synergies through collaborations across the growing
                            ecosystem.
                          </p>
                        </div>
                      </div>
                      {/* <div className="swiper-slide">
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_trust_empowerment.png"
                            />
                          </div>
                          <h4>Trust & Empowerment</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_unleashing_potential.png"
                            />
                          </div>
                          <h4>Unleashing Potential</h4>
                          <p>
                            We’re in it together. Our shared knowledge enables
                            synergies through collaborations across the growing
                            ecosystem.
                          </p>
                        </div>
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_embracing_eachother.png"
                            />
                          </div>
                          <h4>Embracing eachother</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                      </div>
                      <div className="swiper-slide">
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_trust_empowerment.png"
                            />
                          </div>
                          <h4>Trust & Empowerment</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_unleashing_potential.png"
                            />
                          </div>
                          <h4>Unleashing Potential</h4>
                          <p>
                            We’re in it together. Our shared knowledge enables
                            synergies through collaborations across the growing
                            ecosystem.
                          </p>
                        </div>
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_embracing_eachother.png"
                            />
                          </div>
                          <h4>Embracing eachother</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                      </div>

                      <div className="swiper-slide">
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_trust_empowerment.png"
                            />
                          </div>
                          <h4>Trust & Empowerment</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                        <div className="box bg1">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_unleashing_potential.png"
                            />
                          </div>
                          <h4>Unleashing Potential</h4>
                          <p>
                            We’re in it together. Our shared knowledge enables
                            synergies through collaborations across the growing
                            ecosystem.
                          </p>
                        </div>
                        <div className="box bg2">
                          <div className="icon">
                            <img
                              alt=""
                              src="assets/images/icon_embracing_eachother.png"
                            />
                          </div>
                          <h4>Embracing eachother</h4>
                          <p>
                            We believe in trusting great people to make their
                            own decisions. Our shared and inclusive
                            entrepreneurial mindset is our collective driving
                            force.
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
      <div className="empowering">
        <div className="mission_line_full2">
          <svg
            width="100%"
            height="466"
            viewBox="0 0 1920 466"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M-28.9288 463.533C-25.7727 436.69 -7.85101 419.45 11.7227 401.901C63.6129 355.379 128.005 327.243 194.112 307.074C267.468 284.694 346.466 277.076 423.513 278.844C524.852 281.169 623.884 308.166 720.079 336.598C803.219 361.171 886.526 384.839 970.729 405.543C1074.89 431.153 1180.23 456.59 1288.09 457.077C1377.1 457.48 1465.05 440.864 1545.19 401.002C1588.18 379.623 1668 326.141 1648.79 266.088C1637.19 229.812 1593.73 202.92 1559.52 195.623C1526.26 188.53 1464.19 187.561 1441.73 223.47C1426.45 247.89 1447.94 283.185 1469.11 296.417C1514.32 324.667 1597.63 328.442 1644.65 314.348C1711.3 294.374 1754.82 235.233 1808.28 191.531C1846.1 160.612 1881.72 127.094 1922.3 99.627C1968.55 68.328 2018.37 43.8355 2071.85 27.6055C2194.48 -9.60471 2340.58 -15.1427 2439.6 77.0376"
              stroke="#6734F4"
              strokeWidth="3"
              strokeLinecap="round"
              className="svg-elem-1"
            ></path>
          </svg>
        </div>

        <div className="empowering_area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Empowering Great People,Great Companies & Great Ideas</h2>
                <p className="animate-text-from-right">
                  From the bustling hubs of Dubai and Bahrain to the heart of
                  the US innovation scene, our story is far from over.
                  <br /> <br />
                  We're constantly pushing boundaries, fostering a culture of
                  relentless growth, and striving to become the undisputed
                  leader in mobile gaming.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="gap"></div>
              <div className="col-4">
                <h2 className="our_values">Our Values</h2>
              </div>
              <div className="col-4 fadein_bottom_22">
                <div className="our_values_box ">
                  <div className="icon_number">
                    <p>01.</p>{" "}
                    <img alt="" src="assets/images/icon_open_mindedness.png" />
                  </div>
                  <h3>Open-Mindedness</h3>
                  <p>
                    We are open to experiments, ready to take measured risks and
                    give second chances to achieve great results.{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_22">
                <div className="our_values_box ">
                  <div className="icon_number">
                    <p>02.</p>{" "}
                    <img alt="" src="assets/images/icon_responsibility.png" />
                  </div>
                  <h3>Responsibility</h3>
                  <p>
                    We appreciate a serious approach to any task and strive to
                    do our job in the best possible way.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 fadein_bottom_22">
                <div className="our_values_box ">
                  <div className="icon_number">
                    <p>03.</p>{" "}
                    <img alt="" src="assets/images/icon_freedom.png" />
                  </div>
                  <h3>Freedom</h3>
                  <p>
                    We support freedom of self expression, value flexibility in
                    processes and are not afraid to offer daring ideas.{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_22">
                <div className="our_values_box ">
                  <div className="icon_number">
                    <p>04.</p>{" "}
                    <img alt="" src="assets/images/icon_growth.png" />
                  </div>
                  <h3>Growth</h3>
                  <p>
                    We believe in personal development and do everything in our
                    power to support the professional growth of our colleagues
                    and partners.{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_22">
                <div className="our_values_box ">
                  <div className="icon_number">
                    <p>05.</p>{" "}
                    <img alt="" src="assets/images/icon_passion.png" />
                  </div>
                  <h3>Passion</h3>
                  <p>
                    We consider passion as the 'fifth element' that makes our
                    work more effective and brings excitement into our lives.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="mission_vision_area">
          <div className="container">
            <div className="row">
              <div className="mission_line_full_top">
                <div className="mission_line_full">
                  <svg
                    width="100%"
                    height="576"
                    viewBox="0 0 1920 576"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M-80.9422 574.403C-80.4175 551.424 -66.6231 535.393 -51.4547 518.97C-11.2431 475.43 41.0238 446.457 95.3806 424.093C155.698 399.276 221.973 386.506 287.347 381.839C373.333 375.7 459.338 390.636 543.057 407.012C615.415 421.166 687.841 434.54 760.788 445.333C851.023 458.683 942.244 471.793 1033.61 463.577C1109 456.797 1182.13 435.694 1246.8 395.532C1281.48 373.993 1344.79 322.326 1323.72 273.018C1310.99 243.233 1272.05 223.941 1242.5 220.5C1213.78 217.155 1161.15 221.3 1145 253.5C1134.02 275.398 1155.03 303.562 1174.02 313.071C1214.55 333.373 1285.39 329.904 1324.08 314.209C1378.9 291.966 1411.02 238.412 1452.79 197.135C1482.34 167.931 1509.81 136.704 1541.97 110.202C1578.63 80.0023 1618.84 55.2803 1662.83 37.2602C1763.68 -4.05409 1886.93 -20.4307 1978.14 49.6929"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      className="svg-elem-1"
                    ></path>
                  </svg>
                </div>

                <div className="col-6">
                  <div className="mission">
                    <div className="mission_inner">
                      <div className="mission_icom">
                        <span>
                          We empower small startups to turn Into big
                          enterprises.
                        </span>{" "}
                        <img alt="" src="assets/images/mission_icon.png" />
                      </div>
                      <h2>
                        <span>Mission</span>
                        <img alt="" src="assets/images/star_mission.png" />
                      </h2>
                      <p>
                        We provide solutions to increase your ROI, Brand
                        Visibility, Business Scalability, Economic Predictions,
                        Accelerate, Incubate,Invest and impart innovative
                        strategies to sustain your business model.
                      </p>
                      <div className="more_about_us">
                        <a href="#">More ABout us</a>
                      </div>
                      <div className="clr"></div>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="vision">
                    <div className="vision_inner">
                      <div className="vision_icom">
                        <span>We give wings to your dreams.</span>{" "}
                        <img alt="" src="assets/images/vision_icon.png" />
                      </div>
                      <h2>
                        <span>Vision</span>
                        <img alt="" src="assets/images/star_mission.png" />
                      </h2>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                      </p>
                      <p>
                        <br />
                      </p>
                      <div className="more_about_us">
                        <a href="#">More ABout us</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="ceo_ara">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="ceo_pic">
                <div className="parallax-image-inner">
                  <img
                    alt=""
                    src="assets/images/ceo-saad-hameed.png"
                    className="parallax-image"
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="message">
                <h2>
                  <img alt="" src="assets/images/message_icon.png" />
                  Message <br />
                  From CEO{" "}
                </h2>
                <h6 className="wow fadeInUp" data-wow-delay="0.3s">
                  Game district, where innovation meets immersion.
                </h6>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  As the CEO, I'm thrilled to lead a team, passionate about
                  crafting extraordinary gaming experiences that resonate
                  globally. At Game District, we're not just developing games;
                  we're building worlds that captivate and inspire. Our
                  commitment to pushing boundaries and embracing technology
                  ensures we remain at the forefront of the gaming industry. To
                  our incredible community of gamers, partners and enthusiasts,
                  your passion fuels our creativity.{" "}
                </p>
                <p className="wow fadeInUp" data-wow-delay="0.5s">
                  Thank you for being part of the Game District journey – where
                  every pixel, every line of code, and every storyline is
                  crafted with you in mind.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>

        <div className="senior_leadership">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="fadein_bottom_22">
                  <img
                    alt=""
                    src="assets/images/icon_seniorbr_leadership.png"
                  />
                  Senior
                  <br />
                  Leadership
                </h2>
                <p className="animate-text-from-right">
                  The strategic architects and creative visionaries shaping the
                  future of mobile entertainment.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-19">
                <div className="leadership_info_1st">
                  <div className="leadership_info_1st_inner">
                    <div className="leadership_info_1st_front">
                      <img
                        src="http://gamedistrictpublishing.com/GD/assets/images/abubakar_saddique.png"
                        alt="Avatar"
                      />
                    </div>
                    <div className="leadership_info_1st_back">
                      <p>Abubakar Saddique</p>
                    </div>
                  </div>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="col-19">
                <div className="leadership_info_2nd">
                  <div className="leadership_info_2nd_inner">
                    <div className="leadership_info_2nd_front">
                      <img
                        src="http://gamedistrictpublishing.com/GD/assets/images/arfan_iqbal.png"
                        alt="Avatar"
                      />
                    </div>
                    <div className="leadership_info_2nd_back">
                      <p>Arfan Iqbal</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-20">
                <div className="leadership_info_3rd">
                  <div className="leadership_info_3rd_inner">
                    <div className="leadership_info_3rd_front">
                      <img
                        src="http://gamedistrictpublishing.com/GD/assets/images/ahmad_aman.png"
                        alt="Avatar"
                      />
                    </div>
                    <div className="leadership_info_3rd_back">
                      <p>Ahmad Aman</p>
                    </div>
                  </div>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="col-19">
                <div className="leadership_info_2nd">
                  <div className="leadership_info_2nd_inner">
                    <div className="leadership_info_2nd_front">
                      <img
                        src="http://gamedistrictpublishing.com/GD/assets/images/junaid_arshad.png"
                        alt="Avatar"
                      />
                    </div>
                    <div className="leadership_info_2nd_back">
                      <p>Junaid Arshad</p>
                    </div>
                  </div>
                  <div className="clr"></div>
                </div>
              </div>
              <div className="col-19">
                <div className="leadership_info_1st">
                  <div className="leadership_info_1st_inner">
                    <div className="leadership_info_1st_front">
                      <img
                        src="http://gamedistrictpublishing.com/GD/assets/images/asfand_tauqir.png"
                        alt="Avatar"
                      />
                    </div>
                    <div className="leadership_info_1st_back">
                      <p>Asfand Tauqir</p>
                    </div>
                  </div>
                  <div className="clr"></div>
                </div>
              </div>
            </div>

            <div className="head_of_department">
              <div className="row">
                <div className="col-3">
                  <div className="leadership_info_4th">
                    <div className="leadership_info_4th_inner">
                      <div className="leadership_info_4th_front">
                        <img
                          src="http://gamedistrictpublishing.com/GD/assets/images/laiha_tauseef.png"
                          alt="Avatar"
                        />
                      </div>
                      <div className="leadership_info_4th_back">
                        <div className="text">
                          <p>Laiha Tauseef</p>
                        </div>
                      </div>
                    </div>
                    <div className="clr"></div>
                  </div>
                </div>

                <div className="col-3">
                  <div className="leadership_info_4th">
                    <div className="leadership_info_4th_inner">
                      <div className="leadership_info_4th_front">
                        <img
                          src="http://gamedistrictpublishing.com/GD/assets/images/head_of_department-3.png"
                          alt="Avatar"
                        />
                      </div>
                      <div className="leadership_info_4th_back">
                        <div className="text">
                          <p>Head of Department</p>
                        </div>
                      </div>
                    </div>
                    <div className="clr"></div>
                  </div>
                </div>

                <div className="col-6 fadein_bottom_22">
                  <h2>
                    Head of
                    <br />
                    Department
                  </h2>
                  <p>
                    <b>Leading the charge.</b> Their expertise drives results,
                    ensuring each department operates at peak performance.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <div className="leadership_info_4th">
                    <div className="leadership_info_4th_inner">
                      <div className="leadership_info_4th_front">
                        <img
                          src="http://gamedistrictpublishing.com/GD/assets/images/head_of_department-2.png"
                          alt="Avatar"
                        />
                      </div>
                      <div className="leadership_info_4th_back">
                        <div className="text">
                          <p>Head of Department</p>
                        </div>
                      </div>
                    </div>
                    <div className="clr"></div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="leadership_info_4th">
                    <div className="leadership_info_4th_inner">
                      <div className="leadership_info_4th_front">
                        <img
                          src="http://gamedistrictpublishing.com/GD/assets/images/george.png"
                          alt="Avatar"
                        />
                      </div>
                      <div className="leadership_info_4th_back">
                        <div className="text">
                          <h4>George </h4>
                          <p>Head of Technology</p>
                        </div>
                      </div>
                    </div>
                    <div className="clr"></div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="leadership_info_4th">
                    <div className="leadership_info_4th_inner">
                      <div className="leadership_info_4th_front">
                        <img
                          src="http://gamedistrictpublishing.com/GD/assets/images/head_of_department-1.png"
                          alt="Avatar"
                        />
                      </div>
                      <div className="leadership_info_4th_back">
                        <div className="text">
                          <p>Head of Department</p>
                        </div>
                      </div>
                    </div>
                    <div className="clr"></div>
                  </div>
                </div>
              </div>
              <div className="clr"></div>
            </div>
            <div className="clr"></div>
          </div>
        </div>
      </div>

      <div className="our_journey">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Our Journey</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="swiper-container">
                <div className="swiper-wrapper">
                  <div className="swiper-slide c1">
                    <img alt="" src="assets/images/our-journey-slide-1.png" />
                    <img
                      className="slide_top"
                      alt=""
                      src="assets/images/slide_top.jpg"
                    />
                    <div className="info">
                      <h3>Game District went live</h3>
                      <p>
                        In November 2016, Game District become a public company
                        with opening its first office in Lahore.
                      </p>
                      <div className="year">2018</div>
                    </div>
                  </div>
                  <div className="swiper-slide c2">
                    <img alt="" src="assets/images/our-journey-slide-1.png" />
                    <img
                      className="slide_top"
                      alt=""
                      src="assets/images/slide_top.jpg"
                    />

                    <div className="info">
                      <h3>Game District went live</h3>
                      <p>
                        In November 2016, Game District become a public company
                        with opening its first office in Lahore.
                      </p>
                      <div className="year">2018</div>
                    </div>
                  </div>
                  <div className="swiper-slide c3">
                    <img alt="" src="assets/images/our-journey-slide-1.png" />
                    <img
                      className="slide_top"
                      alt=""
                      src="assets/images/slide_top.jpg"
                    />
                    <div className="info">
                      <h3>Game District went live</h3>
                      <p>
                        In November 2016, Game District become a public company
                        with opening its first office in Lahore.
                      </p>
                      <div className="year">2018</div>
                    </div>
                  </div>
                  <div className="swiper-slide c4">
                    <img alt="" src="assets/images/our-journey-slide-1.png" />
                    <img
                      className="slide_top"
                      alt=""
                      src="assets/images/slide_top.jpg"
                    />
                    <div className="info">
                      <h3>Game District went live</h3>
                      <p>
                        In November 2016, Game District become a public company
                        with opening its first office in Lahore.
                      </p>
                      <div className="year">2018</div>
                    </div>
                  </div>
                  <div className="swiper-slide c5">
                    <img alt="" src="assets/images/our-journey-slide-1.png" />
                    <img
                      className="slide_top"
                      alt=""
                      src="assets/images/slide_top.jpg"
                    />
                    <div className="info">
                      <h3>Game District went live</h3>
                      <p>
                        In November 2016, Game District become a public company
                        with opening its first office in Lahore.
                      </p>
                      <div className="year">2018</div>
                    </div>
                  </div>
                </div>
                <div className="swiper-pagination"></div>
                <div className="prev_next_button">
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="ceo_ara">
        <div className="blog_area no_margin">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>
                  <img
                    className="icon_left"
                    alt=""
                    src="assets/images/blog-title-icon.png"
                  />
                  <span>Latest</span>
                  <img
                    className="icon_right"
                    alt=""
                    src="assets/images/blog-title-icon-right.png"
                  />
                </h2>
                <p>
                  <b>Lorem Ipsum is simply dummy text</b> of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since.
                </p>
                <ul className="post_type">
                  <li>
                    <a
                      className="tablinks active"
                      onClick={(event) => openCity(event, "blogs")}
                      //   onclick="openCity(event, 'blogs')"
                    >
                      Blogs
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'interviews')"
                      onClick={(event) => openCity(event, "interviews")}
                    >
                      Interviews
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'socialmedia')"
                      onClick={(event) => openCity(event, "socialmedia")}
                    >
                      Social Media
                    </a>
                  </li>
                  <li>
                    <a
                      className="tablinks"
                      //   onclick="openCity(event, 'events')"
                      onClick={(event) => openCity(event, "events")}
                    >
                      Events
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="clr"></div>
            <div id="blogs" className="tabcontent" style={{ display: "block" }}>
              <div className="row">
                <div className="col-12">
                  <div className="blog_slide">
                    {/* <div className="blog-slider owl-carousel owl-theme"> */}
                    <div className="swiper-container2">
                      <div className="swiper-wrapper">
                        <div className="swiper-slide c1">
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-1.png" />
                            <div className="post_date">12 March 2024</div>
                            <h3>Game District Vanguard Singapore Edition</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-2.png" />
                            <div className="post_date">8 March 2024</div>
                            <h3>Our Wonder Women - Women’s Day</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-3.png" />
                            <div className="post_date">20 Feb 2024</div>
                            <h3>Game District Warriors - Techleague</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-4.png" />
                            <div className="post_date">10 Feb 2024</div>
                            <h3>
                              White Nights Pakistan <br />
                              Meetup
                            </h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="home_blog_post post_bg2">
                            <img alt="" src="assets/images/blog-post-2.png" />
                            <div className="post_date">8 March 2024</div>
                            <h3>Our Wonder Women - Women’s Day</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                        <div className="swiper-slide">
                          <div className="home_blog_post post_bg1">
                            <img alt="" src="assets/images/blog-post-3.png" />
                            <div className="post_date">20 Feb 2024</div>
                            <h3>Game District Warriors - Techleague</h3>
                            <p>
                              <b>Lorem Ipsum is simply dummy text</b> of the
                              printing and typesetting industry. Lorem Ipsum has
                              been the industry's standard dummy text ever
                              since.
                            </p>
                            <div className="read_more">
                              <a href="#">Read more</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="clr"></div>
                  </div>
                </div>
              </div>
            </div>

            <div id="interviews" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-3.png" />
                    <div className="post_date">20 Feb 2024</div>
                    <h3>Game District Warriors - Techleague</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="socialmedia" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="events" className="tabcontent">
              <div className="row">
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-1.png" />
                    <div className="post_date">12 March 2024</div>
                    <h3>Game District Vanguard Singapore Edition</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg2">
                    <img alt="" src="assets/images/blog-post-2.png" />
                    <div className="post_date">8 March 2024</div>
                    <h3>Our Wonder Women - Women’s Day</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home_blog_post post_bg1">
                    <img alt="" src="assets/images/blog-post-3.png" />
                    <div className="post_date">20 Feb 2024</div>
                    <h3>Game District Warriors - Techleague</h3>
                    <p>
                      <b>Lorem Ipsum is simply dummy text</b> of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since.
                    </p>
                    <div className="read_more">
                      <a href="#">Read more</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="meet_the_studios">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img alt="" src="assets/images/meet_the_studios_icon.png" />
                Meet the
                <br />
                Studios
              </h2>
              <p>
                <b>Where passion meets expertise.</b> Discover a collective of
                world-class studios, each dedicated to pushing the boundaries of
                mobile entertainment.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="logo_studios">
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/azel-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/play-spare.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/tap-toy.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/northstar.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/rebel-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/level-zone.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/stickya-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/piggy-panda.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/dead-pixels.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/hmbl.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/omc-games.png" />
                  </a>
                </div>
                <div className="studios_logo">
                  <a href="#">
                    <img alt="" src="assets/images/koko-zone.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="game_changers">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>
                <img alt="" src="assets/images/game_changers-con.png" />
                Game changers
              </h2>
              <p>
                Dive into the inspiring journeys of our talented individuals,
                where passion meets purpose, and dreams become reality.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="slider_img_content">
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/irfan_ahmad_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/irfan_ahmad_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Irfan Ahmad</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/usman_qureshi_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/usman_qureshi_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Usman Qureshi</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item active">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/duaa_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/duaa_1_lead.png"
                  />
                </div>
                <div
                  className="info"
                  style={{ display: "block", width: "36%" }}
                >
                  <h3>Duaa</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
                <div className="item">
                  <img
                    className="pic_lead"
                    alt=""
                    src="assets/images/abubakar-saddique_lead.png"
                  />
                  <img
                    className="pic_lead_active"
                    alt=""
                    src="assets/images/abubakar_saddique_1_lead.png"
                  />
                </div>
                <div className="info">
                  <h3>Abubakar Saddique</h3>
                  <p>Principal digital artist</p>
                  <p>
                    Duaa’s journey to becoming a lead digital artist is filled
                    with hard work, dedication, and a passion for creativity.
                    Starting out as a junior digital artist, she was determined
                    to master the skills necessary to succeed in the competitive
                    world of digital art and design, Duaa spent countless hours
                    practicing and perfecting her craft, constantly seeking out
                    new techniques and software programs to expand her skill
                    set.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div>
      <Footer />
      {/* <div className="contact_us">
        <div className="container">
          <div className="row">
          <ContactUs/>
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
}
