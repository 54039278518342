import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";
import Footer from "./Footer";

export default function GDHub() {
  const [loader, setLoader] = useState(true);

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>GD Hub - Game District</title>
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <script src="assets/js/wow.min.js"></script>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link
          type="text/css"
          href="assets/css/swiper.min.css"
          rel="stylesheet"
        />
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
          function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
new WOW().init();
fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});
    const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
          },3000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
          .owl-item .item .box {
            transform: scale(0.9) !important;
            transition: transform 1s !important;
          }
          .owl-item.active .item .box {
            transform: scale(1) !important;
          }
        `}</style>
      </Helmet>
      <div className="top_home_area">
        <Header />

        <div className="banner_gd_hub">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="wow fadeInUp" data-wow-delay="0.3s">
                  <img
                    className="icon_gd_hub_left"
                    alt=""
                    src="assets/images/icon_gd_hub_left.png"
                  />
                  We Create
                  <span className="star">
                    <img alt="" src="assets/images/star_gd_hub.png" />
                  </span>
                </h2>
                <h1 className="wow fadeInUp" data-wow-delay="0.5s">
                  We challenge the{" "}
                </h1>
                <h1 className="wow fadeInUp" data-wow-delay="0.5s">
                  limits ev
                  <span className="icon_about_title">
                    <img alt="" src="assets/images/icon_gd_hub_bottom.png" />
                  </span>
                  eryday
                </h1>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
      </div>

      <div className="gd_hub_empowering_studios">
        <div className="gd_hub_empowering_studios_area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <img alt="" src="assets/images/gd_hub_map.png" />
              </div>
            </div>
            <div className="gd_hub_empowering_studios_info">
              <div className="row">
                <div className="col-6 fadein_bottom_23">
                  <img alt="" src="assets/images/gd_hub_logo.png" />
                  <h3>Empowering studios of all sizes</h3>
                </div>
                <div className="col-6">
                  <div className="gd_hub_empowering_studios_details">
                    <p className="animate-text-from-right">
                      <b>
                        Game District isn't just a name - it's a collaborative
                        ecosystem where innovation thrives.
                      </b>
                      <br /> <br />
                      We're a network of passionate studios, each with a unique
                      voice and a shared ambition: to push the boundaries of
                      gaming and craft unforgettable experiences. Whether you're
                      a seasoned studio honing your craft or an aspiring
                      developer brimming with ideas, Game District provides the
                      foundation for your success.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="gd_hub_info_area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>GD Hub</h2>
              <p className="animate-text-from-right">
                <b>The GD Hub is a vibrant ecosystem</b> where independent
                studios worldwide can flourish. We believe that incredible
                gaming experiences can come from anywhere, and that's why our
                doors are open to anyone with a vision and the capacity to make
                it a reality.
              </p>
              <p className="inner animate-text-from-right">
                Whether you're a one-person powerhouse or a budding development
                team, the GD Hub offers the support you need to transform your
                dreams into reality.
              </p>
            </div>
          </div>
          <div className="gd_hub_values_box_area">
            <div className="row">
              <div className="col-4 fadein_bottom_12">
                <div className="gd_hub_values_box ">
                  <div className="icon_number">
                    <p>01.</p>{" "}
                    <img alt="" src="assets/images/icon_open_mindedness.png" />
                  </div>
                  <h3>Exclusive Resources</h3>
                  <p>
                    Access workshops, educational materials, and potential
                    co-development opportunities to elevate your game.{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_12">
                <div className="gd_hub_values_box ">
                  <div className="icon_number">
                    <p>02.</p>{" "}
                    <img alt="" src="assets/images/icon_responsibility.png" />
                  </div>
                  <h3>Expert Mentorship</h3>
                  <p>
                    Gain invaluable insights and strategic advice from industry
                    veterans who have "been there, done that."{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_12">
                <div className="gd_hub_values_box ">
                  <div className="icon_number">
                    <p>03.</p>{" "}
                    <img alt="" src="assets/images/icon_freedom.png" />
                  </div>
                  <h3>Enhanced Brand Recognition</h3>
                  <p>
                    Leverage Game District's reputation to boost your studio's
                    profile and attract new opportunities.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-4 fadein_bottom_12">
                <div className="gd_hub_values_box ">
                  <div className="icon_number">
                    <p>04.</p>{" "}
                    <img alt="" src="assets/images/icon_growth.png" />
                  </div>
                  <h3>Community & Collaboration</h3>
                  <p>
                    Connect with a global network of passionate game developers
                    and studios, fostering collaboration and knowledge sharing.{" "}
                  </p>
                </div>
              </div>
              <div className="col-4 fadein_bottom_12">
                <div className="gd_hub_values_box ">
                  <div className="icon_number">
                    <p>05.</p>{" "}
                    <img alt="" src="assets/images/icon_passion.png" />
                  </div>
                  <h3>Global Exposure</h3>
                  <p>
                    Participate in industry showcases and events, gaining
                    valuable exposure for your studio and your game.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <h2 className="our_values">
                  We’re Fuelling the Global Gamescape with Magic.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="games_are_played">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h2>
                We’re Where The Games Are Played!{" "}
                <img
                  className="games_are_played_icon"
                  alt=""
                  src="assets/images/games_are_played_icon.png"
                />
              </h2>
              <p className="animate-text-from-right">
                Fueling the global gaming landscape. Our reach spans continents,
                empowering studios of all sizes to bring their creations to the
                world.
              </p>
            </div>
            <div className="col-6">
              <div className="games_are_played_info">
                <p className="animate-text-from-right">
                  The GD Gamescape doesn't just build games, it builds the
                  future of play. We empower studios to create experiences that
                  will enthrall players across the globe. Here, the games aren't
                  just played - they become part of a shared passion that
                  connects millions.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>
      <Footer />
      {/*  <div className="contact_us">
        <div className="container">
          <div className="row">
          <ContactUs/>
            
            <div className="col-4 fadein_bottom_12">
              <div className="bolt">
                <img alt="" src="assets/images/bolt.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="clr"></div>
      </div>

      <div className="footer">
        <div className="footer_area">
          <div className="container">
            <div className="row">
              <div className="col-3 animate-text-from-bottom">
                <div className="footer_logo">
                  <img alt="" src="assets/images/logo-footer.png" />
                </div>
                <p>Making you realize the power of Digital Intelligence</p>
                <div className="copy_right">© Game District 2024</div>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <a href="about.html">About</a>
                  </li>
                  <li>
                    <a href="games.html">Games</a>
                  </li>
                  <li>
                    <a href="gd-hub.html">GD Hub</a>
                  </li>
                  <li>
                    <a href="ip.html">IP</a>
                  </li>
                  <li>
                    <a href="blog.html">Blogs</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <h3>Programs:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="programs-pixls.html">Pixls</a>
                  </li>
                  <li>
                    <a href="programs-hackathon.html">Hackathon</a>
                  </li>
                </ul>
                <h3>Tools:</h3>
                <ul className="menufooter">
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#gdi">
                      GDI
                    </a>
                  </li>
                  <li>
                    <a href="https://gamedistrictpublishing.com/GD/tools.html#applifier">
                      Applifier
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-2 animate-text-from-bottom">
                <ul className="footer_menu">
                  <li>
                    <a href="#">Career</a>
                  </li>
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="col-3 animate-text-from-bottom">
                <div className="phone">+92423 4550102</div>
                <div className="email">hello@gdbolt.co</div>
                <div className="address">
                  44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                </div>
                <div className="social_icons">
                  <a href="#">
                    <img alt="" src="assets/images/inn.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/facebook.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/instagram.png" />
                  </a>
                  <a href="#">
                    <img alt="" src="assets/images/twitter.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>
        <div className="clr"></div>
      </div> */}
    </>
  );
}
