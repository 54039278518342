import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
import Header from "./Header";
import ContactUs from "./ContactUs";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact - Game District</title>
        <link
          rel="Shortcut Icon"
          href="assets/images/favicon.ico"
          type="image/x-icon"
        />
        <link type="text/css" href="assets/css/style.css" rel="stylesheet" />
        <link
          type="text/css"
          href="assets/css/bootstrap.css"
          rel="stylesheet"
        />
        <script
          src="https://code.jquery.com/jquery-3.7.1.slim.min.js"
          integrity="sha256-kmHvs0B+OpCW5GVHUNjv9rOmY0IvSIRcf7zGUDTDQM8="
          crossorigin="anonymous"
        ></script>
        <link type="text/css" href="assets/css/fonts.css" rel="stylesheet" />
        <link type="text/css" href="assets/css/animate.css" rel="stylesheet" />
        <script src="assets/animation/plugins.js"></script>
        <script src="assets/animation/app.js"></script>
        <script
          src="https://cdn.jsdelivr.net/npm/circletype@2.3.0/dist/circletype.min.js"
          type="text/javascript"
        ></script>
        <script>
          {`
          function fadeIn(targets, properties, options) {
			const elements = document.querySelectorAll(targets);

			if (!elements || elements.length === 0) {
				return;
			}

			gsap.from(elements, {
				...properties,
				stagger: options.stagger || 0.5,
				duration: options.duration || 1,
				ease: options.ease || "power2.out",
				scrollTrigger: {
					trigger: elements,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
		}
          setTimeout(() => {
fadeIn(".fadein_bottom_12", { opacity: 0, y: 100, duration: 3, delay: 0.5 }, { stagger: 0.25 });
const animateTextFromBottom = gsap.utils.toArray(".animate-text-from-bottom");
		animateTextFromBottom.forEach((item) => {
			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					start: "top 90%",
					end: "bottom 60%",
					toggleActions: "play none none none",
				},
			});
			const animateTextFromBottomSplitted = new SplitText(item, { type: "chars, words, lines" });
			animateTextFromBottomSplitted.split({ type: "words" });
			tl.from(animateTextFromBottomSplitted.words, {
				duration: 1,
				y: 50,
				autoAlpha: 0,
				stagger: 0.05,
				onComplete: () => {
					animateTextFromBottomSplitted.revert();
				},
			});
		});
    const text = document.getElementById('text')
    const rotate = new CircleType(text).radius(50)
    text.style.transform = 'rotate(' + (window.scrollY * 0.15) + 'deg)'
          },1000)

    `}
        </script>
        <style jsx="true">{`
          .loaderLayout {
            width: 100%;
            position: fixed;
            height: 100vh;
            left: 0;
            top: 0;
            background: #fff;
            z-index: 99999;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .loaderLogo {
            width: 100px;
          }
          .contact_us {
            margin: 0;
            border-radius: 0;
          }
        `}</style>
      </Helmet>
      <div className="top_home_area">
        <Header />
        <div className="contact_us">
          <div className="container">
            <div className="row">
              <ContactUs />
              {/* <div className="col-4">
                <h1>Contact us</h1>
                <p>
                  Team Game District is just a click away from you. Connect with
                  us to get solutions to your business growth, market existence,
                  and sustainability.
                </p>
                <div className="sign_up">
                  <h4>Stay up to date on global innovations.</h4>
                  <input
                    className="email"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                  />
                  <input
                    className="submit"
                    type="submit"
                    value="Sign Up"
                    name="submit"
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="just_play">
                  <div className="just_play_text">
                    <a href="#" className="circle-btn welcome-img-btn">
                      <span className="circle-btn__text">
                        JUST PLAY GAMES. JUST MAKE GAMES{" "}
                      </span>
                    </a>
                    <img alt="" src="assets/images/just_play_arrow.png" />
                  </div>
                  <div className="submit_project">
                    <input
                      className="text"
                      type="text"
                      name="name"
                      placeholder="Name"
                    />
                    <input
                      className="text"
                      type="email"
                      placeholder="Email Address"
                      name="email"
                    />
                    <div className="select">
                      <select>
                        <option>Category</option>
                        <option>Category1</option>
                        <option>Category2</option>
                        <option>Category3</option>
                        <option>Category4</option>
                        <option>Category5</option>
                        <option>Category6</option>
                      </select>
                    </div>
                    <div className="select">
                      <select>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                        <option>Approx Budget</option>
                      </select>
                    </div>
                    <textarea
                      className="textarea"
                      placeholder="Project Details"
                    ></textarea>
                    <input
                      className="file"
                      type="file"
                      name="file"
                      placeholder="Browse"
                    />
                    <input
                      className="submit"
                      type="submit"
                      value="Submit Project"
                      name="submit"
                    />
                  </div>
                </div>
              </div> */}
              <div className="col-4 fadein_bottom_12">
                <div className="bolt">
                  <img alt="" src="assets/images/bolt.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="clr"></div>
        </div>

        <div className="footer">
          <div className="footer_area">
            <div className="container">
              <div className="row">
                <div className="col-3 animate-text-from-bottom">
                  <div className="footer_logo">
                    <img alt="" src="assets/images/logo-footer.png" />
                  </div>
                  <p>Making you realize the power of Digital Intelligence</p>
                  <div className="copy_right">© Game District 2024</div>
                </div>
                <div className="col-2 animate-text-from-bottom">
                  <ul className="footer_menu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/games">Games</a>
                    </li>
                    <li>
                      <a href="/gdhub">GD Hub</a>
                    </li>
                    <li>
                      <a href="/ip">IP</a>
                    </li>
                    <li>
                      <a href="/blogs">Blogs</a>
                    </li>
                    <li>
                      <a href="/contact">Contact Us</a>
                    </li>
                  </ul>
                </div>
                <div className="col-2 animate-text-from-bottom">
                  <h3>Programs:</h3>
                  <ul className="menufooter">
                    <li>
                      <a href="/pixls">Pixls</a>
                    </li>
                    <li>
                      <a href="/hackathon">Hackathon</a>
                    </li>
                  </ul>
                  <h3>Tools:</h3>
                  <ul className="menufooter">
                    <li>
                      <a href="/tools">GDI</a>
                    </li>
                    <li>
                      <a href="/tools">Applifier</a>
                    </li>
                  </ul>
                </div>
                <div className="col-2 animate-text-from-bottom">
                  <ul className="footer_menu">
                    <li>
                      <a href="#">Career</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/contact">Contact</a>
                    </li>
                  </ul>
                </div>
                <div className="col-3 animate-text-from-bottom">
                  <div className="phone">+92423 4550102</div>
                  <div className="email">hello@gdbolt.co</div>
                  <div className="address">
                    44 D1 MM Alam Rd, Gulberg III, Lahore, Punjab 54000
                  </div>
                  <div className="social_icons">
                    <a href="#">
                      <img alt="" src="assets/images/inn.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/facebook.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/instagram.png" />
                    </a>
                    <a href="#">
                      <img alt="" src="assets/images/twitter.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="clr"></div>
          </div>
          <div className="clr"></div>
        </div>
      </div>
    </>
  );
}
